import { Box, Icon, Flex, Heading, NakedButton } from '@qga/roo-ui/components';
import PropTypes from 'prop-types';
import React from 'react';
import ModalContext from '../../ModalContext';

const Header = ({ children }) => (
  <ModalContext.Consumer>
    {({ onClose }) => (
      <Box p="5" borderBottom="1" borderColor="greys.alto" position="relative">
        <Heading.h6
          data-testid="MODAL_HEADER"
          fontSize="md"
          mb="0"
          textAlign="center"
        >
          {children}
        </Heading.h6>
        {onClose && (
          <Flex position="absolute" right="0" top="0" bottom="0" pr="4">
            <NakedButton data-testid="CLOSE_BUTTON" onClick={() => onClose()}>
              <Icon name="close" />
            </NakedButton>
          </Flex>
        )}
      </Box>
    )}
  </ModalContext.Consumer>
);

Header.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Header;
