import { CreatedEvent } from '../../types';
import { createUser, EventUser } from '../utils/createUser';

export type PageViewArgs = {
  user: EventUser;
  page: {
    location: string;
    referrer: string;
    type: string;
    title: string;
  };
};

export const createPageViewEvent = ({
  user,
  page,
}: PageViewArgs): CreatedEvent => {
  return {
    eventInfo: {
      event: 'page_view',
      event_data: {
        action: 'view',
      },
      site: {
        site_id: 'holidays',
        site_vertical: 'travel',
      },
      content: {
        page_location: page.location,
        page_referrer: page.referrer,
        page_type: page.type,
        page_title: page.title,
      },
      user: createUser(user),
    },
    eventOptions: {
      debugMessage: 'GA4 Data Layer Event (Page View)',
    },
  };
};
