export const QP_BOOKING_HASH = 'QP_BOOKING_HASH';
export const QP_QUOTES_PARAMS = 'QP_QUOTES_PARAMS';

export const FLIGHT_HIGHLIGHTS = [
  'Economy Class',
  'Baggage included',
  'General seat selection (exclusions apply)',
];

export const NOTIFICATION_STORAGE_KEY = 'NOTIFICATION_STORAGE_KEY';
export const POINTS_STORAGE_KEY = 'POINTS_STORAGE_KEY';

export const PREVIOUS_ROUTE_STORAGE_KEY = 'PREVIOUS_ROUTE_STORAGE_KEY';
export const USE_POINTS_PLUS_PAY_STORAGE_KEY =
  'USE_POINTS_PLUS_PAY_STORAGE_KEY';

export const BOOKABLE_PERIOD = {
  min: 2,
  max: 365,
};
export const MAX_STAY = 29;
export const MAX_TRAVELLERS_COUNT = 9;

export const QFF_AUTH_COOKIE = {
  name: 'lsl_auth_data',
  options: {
    path: '/',
    withoutBasePath: true,
  },
};

export const CREATE_CALLBACK_TYPE = {
  REQUEST_CALL_BACK: 'REQUEST_CALL_BACK',
  ABANDON_CART: 'ABANDON_CART',
};

export const FARE_BASIS_CODE_SASH = {
  'HALF OFF FLIGHTS': 'EGQW',
};

export const ALL_DESTINATIONS = 'ALL_DESTINATIONS';

export const ABANDON_CART_MAX_IDLE_PERIOD = 24 * 60 * 60 * 1000;

export const PAY_WITH_POINTS_MIN = 5000;
export const BOUNDARY = {
  min: PAY_WITH_POINTS_MIN,
  max: Number.POSITIVE_INFINITY,
};

export const DEFAULT_OFFER_POINTS = 110000;

export const DEFAULT_SEARCH_PARAMS = {
  children: '0',
  infants: '0',
  travelClass: 'economy',
};

export const DOMESTIC = 'DOMESTIC';
export const INTERNATIONAL = 'INTERNATIONAL';
export const TRANS_TASMAN = 'TRANS_TASMAN';
export const US = 'US';
export const CA = 'CA';

export const INFANT = 'INFANT';
export const ADULT = 'ADULT';
export const CHILD = 'CHILD';

export const FLEXIBLE_OFFER_END_DATE = {
  DOMESTIC: new Date('2022-04-30'),
  TRANS_TASMAN: new Date('2022-12-31'),
  INTERNATIONAL: new Date('2022-12-31'),
};

export const HOTELS_SAVING = '30%';
export const HOLIDAYS_SAVING = '45%';
export const POINTS_SAVING = '30-45%';
export const PRE_POINTS_SAVING_DATE = new Date('2022-02-04');

export const DEALS_PAGE_CAMPAIGN_SLUG = 'special-offers';
export const EXCLUSIVE_OFFERS_PAGE_CAMPAIGN_SLUG = 'exclusive-offers';

export const API_SERVICE_ERROR = {
  EXCEED_API_RATE_LIMIT: 'EXCEED_API_RATE_LIMIT',
};

export const PAY_WITH_OPTIONS = {
  cash: 'cash',
  points: 'points',
  pointsPlusPay: 'pointsPlusPay',
};

export const DOMESTIC_COMMISSION_RATE = 0.115;
export const INTERNATIONAL_COMMISSION_RATE = 0.095;

export const statesLookup = {
  'Australian Capital Territory': 'ACT',
  'New South Wales': 'NSW',
  'Northern Territory': 'NT',
  'South Australia': 'SA',
  'Western Australia': 'WA',
  Queensland: 'QLD',
  Tasmania: 'TAS',
  Victoria: 'VIC',
};

export const ERROR_CODES = {
  DATES_MISMATCHED: 'DATES_MISMATCHED',
};
