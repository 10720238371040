import Router from 'next/router';
import { useEffect } from 'react';
import { PREVIOUS_ROUTE_STORAGE_KEY } from '@/constants';
import useSessionStorage from '@/hooks/useSessionStorage';

const usePreviousRoute = () => {
  const [previousRoute, setPreviousRoute] = useSessionStorage(
    PREVIOUS_ROUTE_STORAGE_KEY,
    '',
  );

  useEffect(() => {
    const handleBeforeHistoryChange = () =>
      setPreviousRoute(window.location.href);
    Router.events.on('beforeHistoryChange', handleBeforeHistoryChange);
    return () =>
      Router.events.off('beforeHistoryChange', handleBeforeHistoryChange);
  }, [setPreviousRoute]);

  return previousRoute;
};

export default usePreviousRoute;
