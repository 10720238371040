import { CreatedEvent, EcommerceDetailedItem } from '../../types';

export type AddToCartEventProps = {
  item: EcommerceDetailedItem;
};

export const createAddToCartEvent = ({
  item,
}: AddToCartEventProps): CreatedEvent => {
  return {
    eventInfo: {
      event: 'add_to_cart',
      event_data: {
        action: 'add',
        component_type: 'cart',
        location: 'Package detail page',
      },
      ecommerce: {
        currency: 'AUD',
        payment_type: item.payment_type,
        value: item.price,
        items: [item],
      },
    },
    eventOptions: {
      debugMessage: 'GA4 Data Layer Event (Add to cart)',
    },
  };
};
