'use client';

import { pick } from 'ramda';
import React, { useCallback } from 'react';
import { LoginEventArgs } from '../../../v2/utils/dataLayer/eventCreators/ga4';
import { getUserUuid } from '../../../v2/utils/localStorage';
import Cookies from 'js-cookie';
import { Member } from '../../../v2/components/AuthContext/types';

export const QFF_HASH_COOKIE_KEY = 'QF_VALUE';

export interface OAuthContext {
  clientId: string;
  redirectUri: string;
  qffAuthApiUrl: string;
  lslAuthApiUrl: string;
  options: {
    flow: boolean;
    onLogin: (memberDetails: Member) => void;
    onLogout: (memberDetails?: Member | undefined) => void;
  };
}

type DataLayer = {
  loginEvent: (data: LoginEventArgs) => void;
};

type OAuthProviderProps = {
  children: React.ReactNode;
  dataLayer: DataLayer;
  config: Omit<OAuthContext, 'options'> & {
    client: string;
  };
};

export const OAuthContext = React.createContext<OAuthContext>({
  clientId: '',
  redirectUri: '',
  qffAuthApiUrl: '',
  lslAuthApiUrl: '',
  options: {
    flow: false,
    onLogin: () => {},
    onLogout: () => {},
  },
});

const OAuthProvider = ({ dataLayer, config, children }: OAuthProviderProps) => {
  const requiredOnlyConfig = pick(
    ['clientId', 'redirectUri', 'qffAuthApiUrl', 'lslAuthApiUrl', 'client'],
    config,
  );

  const onLogin = useCallback(
    (member: Member | undefined) => {
      const id = getUserUuid();
      const qffHash = Cookies.get(QFF_HASH_COOKIE_KEY);

      dataLayer.loginEvent({
        id,
        qffHash,
        points: member?.points,
        loginStatus: 'logged in',
      });
    },
    [dataLayer],
  );

  const onLogout = useCallback(
    (member: Member | undefined) => {
      const id = getUserUuid();
      const qffHash = Cookies.get(QFF_HASH_COOKIE_KEY);

      dataLayer.loginEvent({
        id,
        qffHash,
        points: member?.points,
        loginStatus: 'logged out',
      });
    },
    [dataLayer],
  );

  return (
    <OAuthContext.Provider
      value={{
        ...requiredOnlyConfig,
        options: {
          flow: true,
          onLogin,
          onLogout,
        },
      }}
    >
      {children}
    </OAuthContext.Provider>
  );
};

export default OAuthProvider;
