import Case from 'case';
import { CreatedEvent, EcommerceItem } from '../../types';
import type { SearchQueryValues } from '../../../../hooks/useSearchQuery';

export type SelectItemListEvenProps = {
  origin?: string;
  destination?: string;
  item: EcommerceItem;
  searchType: 'list' | 'map';
  name: string;
  usePoints?: boolean;
  duration?: number;
  searchQuery?: SearchQueryValues;
};

export const createSelectItemEvent = ({
  destination,
  item,
  searchType,
  origin,
  name,
  duration,
  usePoints,
  searchQuery,
}: SelectItemListEvenProps): CreatedEvent => {
  return {
    eventInfo: {
      event: 'select_item',
      event_data: {
        action: 'select',
        component_type: 'item_list',
        search_payment_toggle: usePoints ? 'points' : 'cash',
        destination,
        search_type: searchType,
        origin,
        start_date: searchQuery?.departureDate,
        end_date: searchQuery?.returnDate,
        duration,
        travellers_adult: searchQuery?.adults || 2,
        travellers_children: searchQuery?.children || 0,
        travellers_infant: searchQuery?.infants || 0,
      },
      ecommerce: {
        currency: 'AUD',
        item_list_id: Case.snake(name),
        item_list_name: name,
        items: [item],
      },
    },
    eventOptions: {
      debugMessage: 'GA4 Data Layer Event (Select Item)',
    },
  };
};
