export const createCtaClickEvent = ({
  itemType,
  itemText,
  url,
  itemVariant,
}: {
  itemType?: string;
  itemText: string;
  url?: string;
  itemVariant?: string;
}) => {
  return {
    eventInfo: {
      event: 'cta_click',
      event_data: {
        action: 'click',
        component_type: 'cta',
        ...(itemVariant && { component_variant: itemVariant }),
        item_text: itemText,
        item_type: itemType || 'button',
        url,
      },
    },
    eventOptions: {
      debugMessage: 'GA4 Data Layer Event (CTA Click)',
    },
  };
};
