export const AUTH_STRATEGY = {
  OAUTH: 'oauth',
} as const;
export type AuthStrategy = (typeof AUTH_STRATEGY)[keyof typeof AUTH_STRATEGY];

export interface Member {
  memberId: string | undefined;
  title: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  phoneNumber: string | undefined;
  email: string | undefined;
  points: number | undefined;
  membershipTier: string | undefined;
}
