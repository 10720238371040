import PropTypes from 'prop-types';
import React from 'react';
import { Select as BaseSelect } from '@qga/roo-ui/components';
import FormErrorMessage from '@/shared/components/FormErrorMessage';

const Select = ({ options, error, ...props }) => (
  <div>
    <BaseSelect
      error={!!error}
      mb="0"
      {...props} // eslint-disable-line react/jsx-props-no-spreading
    >
      {options.map((o) => (
        <option key={o.value} value={o.value}>
          {o.text}
        </option>
      ))}
    </BaseSelect>
    {error && <FormErrorMessage text={error} />}
  </div>
);

Select.defaultProps = {
  error: undefined,
};

Select.propTypes = {
  error: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string,
    }),
  ).isRequired,
  ...Select.propTypes,
};

export default Select;
