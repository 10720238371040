import { useContext, useEffect } from 'react';
import { useOAuthState } from './useOAuthState';
import { OAuthContext } from './OAuthProvider';
import { useMemberDetails } from './useMemberDetails';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import { Cookie } from '../../constants/storage';

export const useOAuth = () => {
  const { qffAuthApiUrl, clientId, redirectUri, lslAuthApiUrl, options } =
    useContext(OAuthContext);
  const { route, asPath } = useRouter();

  const [state] = useOAuthState();
  const encodedState = encodeURIComponent(state);
  const { member, getMember, clearMember } = useMemberDetails();

  useEffect(() => {
    if (!asPath.startsWith('/auth')) {
      window.localStorage.setItem(state, JSON.stringify(asPath));
    }
  }, [state, asPath]);

  const login = () => {
    const hasQLSessCookie =
      typeof Cookies.get(Cookie.QL_WL_SESSION) === 'string';
    if (hasQLSessCookie) {
      // @ts-ignore
      window.location = `${lslAuthApiUrl}/oauth?response_type=code&client_id=${clientId}&scope=profile&redirect_uri=${redirectUri}/auth&web=true&state=${encodedState}&prompt=none`;
      return;
    }
    // @ts-ignore
    window.location = `${lslAuthApiUrl}/oauth?response_type=code&client_id=${clientId}&scope=profile&redirect_uri=${redirectUri}/auth&web=true&state=${encodedState}`;
    return;
  };

  const logout = async () => {
    try {
      await fetch(`${qffAuthApiUrl}/holidays/auth/logout`, {
        method: 'POST',
        credentials: 'include',
      }).then(() => {});
    } catch (error) {
      Cookies.remove(Cookie.LSL_USER_ID);
      Cookies.remove(Cookie.QH_SESS);
    }
    try {
      fetch(`${lslAuthApiUrl}/logoff`, {
        credentials: 'include',
      }).then(() => {});
    } catch (error) {}

    clearMember();
    options.onLogout();
  };

  useEffect(() => {
    if (options.flow) {
      if (route !== '/auth') {
        const hasQLSessCookie =
          typeof Cookies.get(Cookie.QL_WL_SESSION) === 'string';
        if (!hasQLSessCookie) {
        } else if (typeof Cookies.get(Cookie.LSL_USER_ID) !== 'string') {
          login();
        }
      }
    }
  }, [login, member, options.flow, route]);

  return { login, logout, member, getMember };
};

export default useOAuth;
