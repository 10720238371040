import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import getFeatureFlag from '../../../../utils/getFeatureFlag';
import WebChatContext from '../../WebChatContext';
import Register from '../Register';
import isOpeningHours from './helpers/isOpeningHours';
import dataLayer from '@/v2/utils/dataLayer';
import { useGA4Events } from '@/v2/hooks/featureFlags/ga4Events/useGA4Events';

const Provider = ({ children }) => {
  const shouldUseNewEvents = useGA4Events();
  const [webChat, setWebChat] = useState();
  const [enabled, setEnabled] = useState(true);
  const [opened, setOpened] = useState(false);
  const [opening, setOpening] = useState(false);
  const [specialHours, setSpecialHours] = useState([]);

  const offline = useMemo(() => !isOpeningHours(specialHours), [specialHours]);

  const value = useMemo(
    () =>
      webChat && {
        // TURNING WEB CHAT OFF PER CUSTOMER SERVICE TEAM REQUEST
        enabled: false,
        opened,
        opening,
        offline,
        setSpecialHours,
        disable: () => setEnabled(false),
        enable: () => setEnabled(true),
        open: () => {
          webChat.command('WebChat.open');
          setOpening(true);
          if (shouldUseNewEvents) {
            dataLayer.chatEvent({ action: 'start' });
          }
        },
        close: () => {
          webChat.command('WebChat.close');
          webChat.command('WebChat.endChat');
          if (shouldUseNewEvents) {
            dataLayer.chatEvent({ action: 'close' });
          }
        },
      },
    [offline, opened, opening, shouldUseNewEvents, webChat],
  );

  useEffect(() => {
    if (!webChat) {
      return;
    }

    webChat.subscribe('WebChat.opened', () => {
      setOpening(false);
      setOpened(true);
    });

    webChat.subscribe('WebChat.closed', () => {
      setOpened(false);
    });
  }, [webChat]);

  return (
    <WebChatContext.Provider value={value}>
      {children}
      {getFeatureFlag('WEB_CHAT') && <Register onRegister={setWebChat} />}
    </WebChatContext.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Provider;
