import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { omit } from 'ramda';
import { useSessionStorage } from 'react-use';
import {
  SessionStorage,
  SessionStorageDefaultValue,
} from '@/libs/v2/constants/storage';
import { isPaymentType } from '@/components/CustomerSelectionProvider/utils';
import { PaymentType } from '@/components/CustomerSelectionProvider/types';

const usePaymentTypeState = () =>
  useSessionStorage<PaymentType>(
    SessionStorage.PAYMENT_TYPE,
    SessionStorageDefaultValue.PAYMENT_TYPE,
  );

const useInitialisePaymentTypeFromUrl = (
  setPaymentType: (paymentType: PaymentType) => void,
) => {
  const { query, pathname, replace } = useRouter();

  useEffect(() => {
    const urlPayWith = query.payWith;

    if (isPaymentType(urlPayWith)) {
      setPaymentType(urlPayWith);

      replace(
        {
          pathname,
          query: { ...omit(['payWith'], query) },
        },
        undefined,
        { shallow: true },
      );
    }
  }, [pathname, query, replace, setPaymentType]);
};

const useSetDefaultPaymentTypeIfStorageIsInvalid = (
  paymentType: unknown,
  setPaymentType: (paymentType: PaymentType) => void,
) => {
  useEffect(() => {
    if (!isPaymentType(paymentType)) {
      setPaymentType(SessionStorageDefaultValue.PAYMENT_TYPE);
    }
  }, [paymentType, setPaymentType]);
};

export const usePaymentType = () => {
  const [paymentType, setPaymentType] = usePaymentTypeState();

  useInitialisePaymentTypeFromUrl(setPaymentType);
  useSetDefaultPaymentTypeIfStorageIsInvalid(paymentType, setPaymentType);

  return [paymentType, setPaymentType] as const;
};
