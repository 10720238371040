export const createContactUsFormEvent = ({
  action,
}: {
  action: 'start' | 'complete';
}) => {
  return {
    eventInfo: {
      event: `form_${action}`,
      event_data: {
        action,
        component_type: 'form',
        form: {
          form_name: 'Contact us',
        },
      },
    },
    eventOptions: {
      debugMessage: 'GA4 Data Layer Event (Contact Us Form)',
    },
  };
};
