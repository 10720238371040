import { SEARCH_PARAMS_STORAGE_KEY } from '../../../../constants';
import useSessionStorage from '../useSessionStorage';

const useSearchParams = () => {
  const [searchParams, setSearchParams] = useSessionStorage(
    SEARCH_PARAMS_STORAGE_KEY,
    {},
  );

  return [searchParams, setSearchParams];
};

export default useSearchParams;
