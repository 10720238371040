import { useContext, useEffect } from 'react';
import { useRouter } from 'next/router';

import type { NextPage } from 'next';
import { useOAuthState } from './useOAuthState';
import { OAuthContext } from './OAuthProvider';
import styled from '@emotion/styled';
import { useAuth } from '../../components/AuthContext';
import { Cookie } from '../../constants/storage';
import Cookies from 'js-cookie';
import { AUTH_ROUTE } from './constants';

const Backdrop = styled.div`
  position: fixed;
  inset: 0;
  z-index: 9999999;
  background: white;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const AuthPage: NextPage = () => {
  const { qffAuthApiUrl, clientId } = useContext(OAuthContext);
  const { getMemberDetails } = useAuth();
  const {
    pathname,
    query: { state, code, error },
    replace,
    isReady,
  } = useRouter();

  const [savedState] = useOAuthState();

  useEffect(() => {
    const handle = async () => {
      if (pathname !== AUTH_ROUTE || !isReady) return;

      if (typeof state !== 'string') {
        return replace('/');
      }

      let previousRoute = '/';

      try {
        const localStorageState = localStorage.getItem(savedState);
        previousRoute = localStorageState
          ? JSON.parse(localStorageState)
          : null;
      } catch (error) {
        // Catch potential error of no localStorage
      }

      const redirectRoute = previousRoute?.startsWith('/')
        ? previousRoute
        : '/';

      if (typeof error === 'string' && error !== '') {
        if (error === 'login_required') {
          Cookies.remove(Cookie.QL_WL_SESSION);
          Cookies.remove(Cookie.QL_WL_SESSION, {
            domain: 'qantas.com',
            path: '/',
          });
          Cookies.remove(Cookie.QL_WL_SESSION, {
            domain: '.qantas.com',
            path: '/',
          });
        }
        return replace(redirectRoute);
      }

      if (decodeURIComponent(state) !== savedState) {
        return replace(redirectRoute);
      }
      if (typeof code !== 'string') {
        return replace(redirectRoute);
      }

      const url = new URL(`${qffAuthApiUrl}/holidays/auth/callback`);
      url.searchParams.append('code', code);
      url.searchParams.append('state', state);
      url.searchParams.append('client_id', clientId);

      const response = await fetch(url.toString(), { credentials: 'include' });

      return replace(redirectRoute);
    };

    handle().then(() => {
      getMemberDetails();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, state, isReady]);

  return <Backdrop></Backdrop>;
};

export default AuthPage;
