import { UI } from '@/v2/types';

const PAGE_NAME_ROUTES: Record<UI.PageName, string[]> = {
  package: [
    '/[destinationName]/hotel-and-flight-packages/[[...params]]',
    '/[destinationName]/properties/[propertyId]/package/[[...params]]',
  ],
  deals: ['/deals'], // Exclusive offer
  destination: ['/[destinationName]/[[...params]]'], // Destination + Theme
  checkout: ['/checkout'],
  'contact-us': ['/contact-us'],
  faqs: ['/faqs'],
  'home-page': ['/'],
  campaign: ['/deals/[campaignSlug]'],
};

export default PAGE_NAME_ROUTES;
