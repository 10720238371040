import md5 from 'md5';
import { CREATE_CALLBACK_TYPE, QP_QUOTES_PARAMS } from '@/constants';
import { addDays } from '@/lib/date';
import BFF from '@/services/BFF';

export const ABANDONED_CART = 'ABANDONED_CART';

const onAbandon = (type, memberDetails) => {
  const cart = sessionStorage.getItem(QP_QUOTES_PARAMS);

  if (!memberDetails || !cart) {
    return;
  }

  const abandonedCart = md5(cart);

  if (abandonedCart === sessionStorage.getItem(ABANDONED_CART)) {
    return;
  }

  const quote = JSON.parse(cart);

  const { phoneNumber, memberId, firstName, lastName } = memberDetails;

  const scheduledTime = {
    INACTIVE_SESSION: new Date().toISOString(),
    DROPPED_SESSION: addDays(new Date(), 1).toISOString(),
  }[type];

  const data = {
    qff: memberId,
    name: `${firstName} ${lastName}`,
    type,
    propertyId: quote.propertyId,
    propertyName: quote.propertyName,
    roomTypeId: quote.roomTypeId,
    roomTypeName: quote.roomTypeName,
    offerLabel: quote.offerLabel,
    origin: quote.originCode,
    destination: quote.destination,
    departureDate: quote.departureDate,
    returnDate: quote.returnDate,
    adults: quote.adults,
    children: quote.children,
    infants: quote.infants,
    outbound: quote.flights.outbound
      .map(({ flightNumber }) => flightNumber)
      .join(','),
    inbound: quote.flights.inbound
      .map(({ flightNumber }) => flightNumber)
      .join(','),
  };

  BFF.createCallback()({
    type: CREATE_CALLBACK_TYPE.ABANDON_CART,
    phoneNumber,
    scheduledTime,
    data,
  });

  sessionStorage.setItem(ABANDONED_CART, abandonedCart);
};

export default onAbandon;
