import React, { Dispatch, PropsWithChildren, useMemo } from 'react';
import useSearchParams from './hooks/useSearchParams';

interface SessionStorageContextType {
  searchParams: { [key: string]: string | any | undefined };
  setSearchParams: Dispatch<{ [key: string]: string | any | undefined }>;
}

export const SessionStorageContext =
  React.createContext<SessionStorageContextType>({
    searchParams: {},
    setSearchParams: (_: { [key: string]: string | any | undefined }) => {},
  });

const SessionStorageProvider = ({ children }: PropsWithChildren<{}>) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const value = useMemo(
    () => ({
      searchParams,
      setSearchParams,
    }),
    [searchParams, setSearchParams],
  );

  return (
    <SessionStorageContext.Provider value={value}>
      {children}
    </SessionStorageContext.Provider>
  );
};

export default SessionStorageProvider;
