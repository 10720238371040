import { useEffect, useState } from 'react';

const useSessionStorage = (key: string, initialValue?: any) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    try {
      const storageValue = sessionStorage.getItem(key);
      const parsedValued = storageValue && JSON.parse(storageValue);
      setValue(parsedValued);
    } catch (error) {
      // If user is in private mode or has storage restriction
    }
  }, [key]);

  useEffect(() => {
    try {
      if (value !== undefined) {
        sessionStorage.setItem(key, JSON.stringify(value));
      }
    } catch (error) {
      // If user is in private mode or has storage restriction
    }
  }, [key, value]);

  return [value, setValue];
};

export default useSessionStorage;
