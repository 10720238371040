import { ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Selectors } from './types';

interface Props {
  children: ReactNode;
  selector: Selectors;
}

const Portal: React.FC<Props> = ({ children, selector }) => {
  const id = `#${selector}`;
  const [element, setElement]: [Element | null, Function] = useState(null);

  useEffect(() => {
    const target = document.querySelector(id);

    if (!target) {
      document.addEventListener('DOMContentLoaded', () => {
        setElement(document.querySelector(id));
      });

      return;
    }

    setElement(target);
  }, [id]);

  return element ? createPortal(children, element) : null;
};

export default Portal;
