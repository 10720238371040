/* eslint-disable max-len */
export {
  accessibility,
  accessTime,
  add,
  airportShuttle,
  allInclusive,
  arrowBack,
  arrowDownward,
  arrowForward,
  arrowUpward,
  beachAccess,
  cake,
  calendar,
  call,
  chat,
  checkBox,
  checkCircle,
  chevronLeft,
  chevronRight,
  childCare,
  childFriendly,
  circle,
  circleBorder,
  circleHalf,
  close,
  confirmation,
  dateRange,
  directionsBus,
  directionsCar,
  done,
  errorOutline,
  event,
  eventAvailable,
  eventNote,
  expandLess,
  expandMore,
  favorite,
  flight,
  flightLand,
  flightRight,
  flightTakeoff,
  freeBreakfast,
  freeCancellation,
  headsetMic,
  hotel,
  image,
  info,
  infoOutline,
  keyboardArrowLeft,
  keyboardArrowRight,
  launch,
  localActivity,
  localBar,
  localDining,
  localMall,
  localOffer,
  localParking,
  localPlay,
  locationCity,
  locationOn,
  mailOutline,
  menu,
  modeEdit,
  naturePeople,
  openInNew,
  people,
  person,
  place,
  pointsPay,
  qffPoints,
  remove,
  restaurant,
  roo,
  roomService,
  schedule,
  spa,
  star,
  starBorder,
  starHalf,
  stars,
  stayCurrentPortrait,
  terrain,
  warning,
  wifi,
  creditCard,
  cameraAlt,
  check,
  cardGiftcard,
  face,
  flag,
  golfCourse,
  hotTub,
  liquor,
  localCafe,
  sailing,
  wineBar,
  done as tick,
} from '@qga/roo-ui/icons';

const warningOutline = {
  category: 'qantas',
  path: 'M2.26667 19.9589C2.62222 20.6214 3.24444 21 3.95556 21H20.0444C20.7556 21 21.3778 20.6214 21.7333 19.9589C22.0889 19.32 22.0889 18.5392 21.7333 17.9004L13.6889 3.0411C13.3333 2.37858 12.7111 2 12 2C11.2889 2 10.6667 2.40224 10.3111 3.0411L2.26667 17.9004C1.91111 18.5392 1.91111 19.32 2.26667 19.9589ZM19.9745 18.8525C20.0047 18.9071 20.0047 18.9522 19.9858 18.9863C19.9827 18.992 19.9808 18.9965 19.9799 19H4.02206C3.99535 18.9456 3.99664 18.9046 4.01424 18.873L12 4.12238L19.9745 18.8525ZM13 15V9H11V15H13ZM13 18V16H11V18H13Z',
};

const flightLeft = {
  category: 'qantas',
  path: 'm16 3h-2l-5 8h-5.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5h5.5l5 8h2l-2.5-8h5.5l1.5 2h1.5l-1-3.5 1-3.5h-1.5l-1.5 2h-5.5z',
};

// Replace with import holiday once v5.11.0 of roo
const holiday = {
  category: 'qantas',
  path: 'M 0,9.40001 a 3.3,3.3 0 1,0 6.6,0 a 3.3,3.3 0 1,0 -6.6,0 M21.7 5.6C22.9 5.3 23.4 4.9 23.4 4.8C23.5 4.7 23.5 4.6 23.5 4.5C23.5 4.4 23.4 4.3 23.4 4.2C23.3 4.1 21.9 2.9 19.9 2.9C19.5 2.9 19.1 2.9 18.8 3C19.1 1.8 19.4 0.5 19.5 0.5C19.5 0.3 19.5 0.2 19.4 0.1C19.2 0 19.1 0 18.9 0C18.8 0 16.7 0.9 15.7 2.3C14.5 1.7 13.4 1.5 12.7 1.5C12.1 1.5 11.7 1.6 11.7 1.6C11.6 1.6 11.5 1.8 11.4 1.9C11.4 2 11.4 2.2 11.5 2.3L13.2 4C12 4.1 10.1 4.6 9 6.7C8.9 6.8 8.9 7 9 7.1C9.1 7.2 9.2 7.3 9.4 7.3C10.3 7.2 11 7.2 11.7 7.2C12.1 7.2 12.4 7.2 12.6 7.2C12.1 8 11.4 9.8 12.2 12.7C12.2 12.9 12.4 13 12.5 13C12.7 13 12.8 13 12.9 12.8C13.1 12.5 14.3 10.3 15.2 8.7C15.5 12.2 15.3 17.2 12.3 20.9C11.5 20.7 10.7 20.6 9.8 20.6C4.8 20.7 2 24 2 24H18.3C18.3 24 16.6 22.5 14 21.5C16.9 17.5 17.2 12.3 16.9 8.7L20.4 12.8C20.5 12.9 20.6 12.9 20.7 12.9H20.8C21 12.8 21.1 12.7 21.1 12.5C21.1 12.4 21.2 10.3 20.2 8.1C21.8 9.1 22.9 9.6 23 9.6C23.1 9.7 23.3 9.6 23.4 9.6C23.5 9.5 23.6 9.4 23.6 9.3C23.6 9.3 23.7 7.3 21.7 5.6Z',
};

const calendarSimple = {
  category: 'qantas',
  path: 'M9 11.5H7V13.5H9V11.5ZM13 11.5H11V13.5H13V11.5ZM17 11.5H15V13.5H17V11.5ZM19 4.5H18V2.5H16V4.5H8V2.5H6V4.5H5C3.89 4.5 3.01 5.4 3.01 6.5L3 20.5C3 21.6 3.89 22.5 5 22.5H19C20.1 22.5 21 21.6 21 20.5V6.5C21 5.4 20.1 4.5 19 4.5ZM19 20.5H5V9.5H19V20.5Z',
};

const user = {
  category: 'qantas',
  path: 'M12 13.5C9.23772 13.5 7 11.2623 7 8.5C7 5.73772 9.23772 3.5 12 3.5C14.7623 3.5 17 5.73772 17 8.5C17 11.2623 14.7623 13.5 12 13.5ZM12 11.5C13.6577 11.5 15 10.1577 15 8.5C15 6.84228 13.6577 5.5 12 5.5C10.3423 5.5 9 6.84228 9 8.5C9 10.1577 10.3423 11.5 12 11.5ZM21 21.5H19V19.5C19 18.0399 15.156 16.5 12 16.5C8.844 16.5 5 18.0399 5 19.5V21.5H3V19.5C3 16.4353 7.83098 14.5 12 14.5C16.169 14.5 21 16.4353 21 19.5V21.5Z',
};

const closeRounded = {
  category: 'qantas',
  path: 'M16.2422 6.34319C16.6327 5.95266 17.2659 5.95266 17.6564 6.34319C18.0469 6.73371 18.0469 7.36688 17.6564 7.7574L13.4138 12L17.6564 16.2427C18.0469 16.6332 18.0469 17.2664 17.6564 17.6569C17.2659 18.0474 16.6327 18.0474 16.2422 17.6569L11.9996 13.4143L7.75691 17.6569C7.36639 18.0474 6.73322 18.0474 6.3427 17.6569C5.95217 17.2664 5.95217 16.6332 6.3427 16.2427L10.5853 12L6.3427 7.7574C5.95217 7.36688 5.95217 6.73371 6.3427 6.34319C6.73322 5.95266 7.36639 5.95266 7.75691 6.34319L11.9996 10.5858L16.2422 6.34319Z',
};

export {
  flightLeft,
  holiday,
  warningOutline,
  calendarSimple,
  user,
  closeRounded,
};
