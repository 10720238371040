import { pathOr } from 'ramda';
import FEATURE_FLAGS from '@/config/featureFlags.json';
import { ENVIRONMENT } from '@/config/public';

export const createFeatureFlagGetter = (environment, featureFlags) => {
  const thisFeatureFlags = featureFlags[environment] || {};

  const OVERWRITE = {
    POINTS_PAY: thisFeatureFlags.QFF_AUTH && thisFeatureFlags.POINTS_PAY,
  };

  return (key) => {
    if (!environment || ['localhost', 'test'].includes(environment)) {
      return true;
    }

    return pathOr(thisFeatureFlags[key], [key], OVERWRITE);
  };
};

export default createFeatureFlagGetter(ENVIRONMENT, FEATURE_FLAGS);
