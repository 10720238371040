import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { BASE_PATH } from '../../config/public';
import { getUserUuid } from '@experiences-ui/shared/v2/utils/localStorage';

const getCorrelationIdHeader = () => {
  const correlationId = uuidv4();

  return {
    'X-Correlation-Id': correlationId,
  };
};

const getUserIdHeader = (ctx) => {
  const { req } = ctx;

  if (req) {
    // server
    return {
      ...(req.headers['X-Feature-Flag-User-Id'] && {
        'X-Feature-Flag-User-Id': req.headers['X-Feature-Flag-User-Id'],
      }),
    };
  }

  if (typeof window !== 'undefined') {
    // client
    return {
      'X-Feature-Flag-User-Id': getUserUuid(),
    };
  }

  return {};
};

export const port = process.env.PORT || 3000;
export const bffService = `${BASE_PATH}/api`;
export const baseHeaders = {};

const getAPIRequest = (ctx = {}) => {
  const { req } = ctx;

  const baseURL = req ? `http://localhost:${port}${bffService}` : bffService;

  const headers = {
    ...baseHeaders,
    ...getCorrelationIdHeader(),
    ...getUserIdHeader(ctx),
  };

  const instance = axios.create({
    baseURL,
    headers,
  });

  return instance;
};

export default getAPIRequest;
