import Router from 'next/router';
import PropTypes from 'prop-types';
import React, { useEffect, useReducer } from 'react';
import ProgressBar from './components/ProgressBar';

const ProgressBarContext = React.createContext(() => {});

const reducer = (state, action) => state + action;
const showProgressBar = (dispatch) => () => dispatch(+1);
const hideProgressBar = (dispatch) => () => dispatch(-1);

export const ProgressBarProvider = ({ children }) => {
  const [inProgressCount, dispatch] = useReducer(reducer, 0);

  useEffect(() => {
    const { events } = Router;

    const show = showProgressBar(dispatch);
    const hide = hideProgressBar(dispatch);

    events.on('routeChangeStart', show);
    events.on('routeChangeComplete', hide);
    events.on('routeChangeError', hide);

    return () => {
      events.off('routeChangeStart', show);
      events.off('routeChangeComplete', hide);
      events.off('routeChangeError', hide);
    };
  }, []);

  return (
    <>
      <ProgressBarContext.Provider
        value={{
          showProgressBar: showProgressBar(dispatch),
          hideProgressBar: hideProgressBar(dispatch),
        }}
      >
        {inProgressCount > 0 && <ProgressBar />}
        {children}
      </ProgressBarContext.Provider>
    </>
  );
};
ProgressBarProvider.propTypes = {
  children: PropTypes.node,
};
ProgressBarProvider.defaultProps = {
  children: null,
};

export default ProgressBarContext;
