import { CreatedEvent } from './types';
import { createPush } from './utils';
import {
  createPageViewEvent as createPageViewEventGA3,
  createUserInteractionsEvent,
} from './eventCreators/ga3';
import {
  createLoginEvent,
  createSearchExitFieldEvent,
  createSearchSubmitEvent,
  createViewItemListEvent,
  createViewItemEvent,
  createPageViewEvent,
  createViewSearchResultsEvent,
  createViewPromotionEvent,
  createSelectPromotionEvent,
  createSelectItemEvent,
  createContactUsFormEvent,
  createChatEvent,
  createMenuEvent,
  createMapEvent,
  createGalleryOpenEvent,
  createAccordionOpenEvent,
  createTileClickEvent,
  createCheckoutErrorEvent,
  createSearchErrorEvent,
  createCtaClickEvent,
  createCheckoutEvent,
  createPurchaseEvent,
  createAddToCartEvent,
} from './eventCreators/ga4';

export type CreateDataLayerOptions = {
  useDebug?: boolean;
};

const createDataLayer = (options?: CreateDataLayerOptions) => {
  const { useDebug } = options ?? {};
  const push = createPush({ useDebug });

  // Wrap event creators with this to create a function that will push
  // the event to the datalayer when called.
  const _withPush =
    <T extends any[]>(eventCreator: (...args: T) => CreatedEvent) =>
    (...args: T) => {
      const { eventInfo, eventOptions } = eventCreator(...args);
      push(eventInfo, eventOptions);
    };

  // GA3 Events
  const userInteractionsEvent = _withPush(createUserInteractionsEvent);
  const pageViewEventGA3 = _withPush(createPageViewEventGA3);

  // GA4 Events
  const loginEvent = _withPush(createLoginEvent);
  const searchExitFieldEvent = _withPush(createSearchExitFieldEvent);
  const searchSubmitEvent = _withPush(createSearchSubmitEvent);
  const viewItemListEvent = _withPush(createViewItemListEvent);
  const viewItemEvent = _withPush(createViewItemEvent);
  const pageViewEvent = _withPush(createPageViewEvent);
  const viewSearchResultsEvent = _withPush(createViewSearchResultsEvent);
  const viewPromotionEvent = _withPush(createViewPromotionEvent);
  const selectPromotionEvent = _withPush(createSelectPromotionEvent);
  const selectItemEvent = _withPush(createSelectItemEvent);
  const contactUsFormEvent = _withPush(createContactUsFormEvent);
  const chatEvent = _withPush(createChatEvent);
  const menuEvent = _withPush(createMenuEvent);
  const mapEvent = _withPush(createMapEvent);
  const galleryOpenEvent = _withPush(createGalleryOpenEvent);
  const accordionOpenEvent = _withPush(createAccordionOpenEvent);
  const tileClickEvent = _withPush(createTileClickEvent);
  const checkoutErrorEvent = _withPush(createCheckoutErrorEvent);
  const searchErrorEvent = _withPush(createSearchErrorEvent);
  const ctaClickEvent = _withPush(createCtaClickEvent);
  const checkoutEvent = _withPush(createCheckoutEvent);
  const purchaseEvent = _withPush(createPurchaseEvent);
  const addToCartEvent = _withPush(createAddToCartEvent);

  return {
    push,
    userInteractionsEvent,
    pageViewEventGA3,
    loginEvent,
    searchExitFieldEvent,
    searchSubmitEvent,
    viewItemListEvent,
    viewItemEvent,
    pageViewEvent,
    viewSearchResultsEvent,
    viewPromotionEvent,
    selectPromotionEvent,
    selectItemEvent,
    contactUsFormEvent,
    chatEvent,
    menuEvent,
    mapEvent,
    galleryOpenEvent,
    accordionOpenEvent,
    tileClickEvent,
    checkoutErrorEvent,
    searchErrorEvent,
    ctaClickEvent,
    checkoutEvent,
    purchaseEvent,
    addToCartEvent,
  };
};

export default createDataLayer;
