import { compose, flatten, keys, map, omit, pick, uniq } from 'ramda';
import getCheapestFlightPrices from '@/libs/apis/GetProcuredOffer/helpers/getCheapestFlightPrices';
import getStandardFlightPrices from '@/libs/apis/GetProcuredOffer/helpers/getStandardFlightPrices';
import {
  getTravellersCount,
  mapOccupants,
  getProcuredPackageOffers,
} from '@/libs/utils';
import { SANITY_QUERY_BRAND } from '@/libs/config/server';
import getProcuredOfferEndDate from './helpers/getProcuredOfferEndDate';

import parseTerms from './parseTerms';
import { createInclusionsLookup } from '../../../v2/utils/inclusionSet';

const parseProcuredOffer = (procuredOffer) => {
  let cheapestFlightPrices,
    standardFlightPrices = null;

  const includesHolidays = procuredOffer.brands.includes(
    `${SANITY_QUERY_BRAND}-holidays`,
  );

  if (includesHolidays) {
    cheapestFlightPrices = getCheapestFlightPrices(
      procuredOffer.flights,
      procuredOffer.destinationCode,
    );
    standardFlightPrices = getStandardFlightPrices(
      procuredOffer.flights,
      procuredOffer.destinationCode,
    );
  }

  const inclusionsLookup = createInclusionsLookup(procuredOffer.inclusionSets);

  const rooms = procuredOffer.rooms.map((room) => ({
    ...room,
    leadInOffers: room.leadInOffers
      .filter((leadInOffer) => leadInOffer.offerTotal)
      .map((leadInOffer) => {
        const { travellers, inclusionSetId, inclusions } = leadInOffer;

        const parsedInclusions =
          inclusionsLookup?.get(inclusionSetId || '') ?? inclusions;

        return {
          ...omit(['offerTotal', 'valuedAtTotal'], leadInOffer),
          inclusions: parsedInclusions,
          inclusionSetId: inclusionSetId ?? null,
          travellers: {
            ...travellers,
            total: getTravellersCount(mapOccupants(travellers)),
          },
          ...(procuredOffer.brands.includes('qantas-hotels')
            ? {
                staysOnlyOffer: pick(
                  ['offerTotal', 'valuedAtTotal'],
                  leadInOffer,
                ),
              }
            : {}),
          packageOffers: getProcuredPackageOffers(
            leadInOffer,
            cheapestFlightPrices,
            standardFlightPrices,
          ),
        };
      }),
  }));

  let origins;

  if (includesHolidays) {
    origins = rooms.map(({ leadInOffers }) =>
      leadInOffers.map(({ packageOffers }) =>
        Object.keys(packageOffers).map((route) => route.substring(0, 3)),
      ),
    );
  }
  const { packageOffers } = rooms[0].leadInOffers[0];

  const routes = compose(
    map((key) => {
      const { travelDates: RouteTravelDates, ...prices } = packageOffers[key];

      const packageTravelDates =
        RouteTravelDates?.map(({ start, end }) => {
          const packageStartDate = isAfter(
            new Date(start.utc),
            new Date(travelDateRange.start.utc),
          )
            ? start
            : travelDateRange.start;

          const packageEndDate =
            isBefore(new Date(end.utc), new Date(travelDateRange.end.utc)) ||
            isAfter(new Date(start.utc), new Date(travelDateRange.end.utc))
              ? end
              : travelDateRange.end;

          return {
            start: packageStartDate,
            end: packageEndDate,
          };
        }) || null;

      return {
        origin: key.substring(0, 3),
        price: { ...prices },
        travelDates: packageTravelDates,
      };
    }),
    keys,
  )(packageOffers);

  const { ['flights']: removeFlights, ...rest } = procuredOffer;

  return {
    ...rest,
    rooms,
    saleDateRange: {
      ...procuredOffer.saleDateRange,
      end: getProcuredOfferEndDate(
        procuredOffer.saleDateRange,
        procuredOffer.flights,
      ),
    },
    origins: origins ? uniq(flatten(origins).sort()) : null,
    routes,
    terms:
      (procuredOffer.terms &&
        parseTerms({
          hotelName: procuredOffer.property.name,
          minNights: rooms[0].leadInOffers[0].minNumberOfNights,
          routes,
          saleEndDate: getProcuredOfferEndDate(
            procuredOffer.saleDateRange,
            procuredOffer.flights,
          ),
          saleStartDate: procuredOffer.saleDateRange.start,
          terms: procuredOffer.terms,
          travelDateRange: procuredOffer.travelDateRange,
        })) ||
      null,
    hotelTerms:
      (procuredOffer.hotelTerms &&
        parseTerms({
          hotelName: procuredOffer.property.name,
          minNights: rooms[0].leadInOffers[0].minNumberOfNights,
          routes,
          saleEndDate: getProcuredOfferEndDate(
            procuredOffer.saleDateRange,
            procuredOffer.flights,
          ),
          saleStartDate: procuredOffer.saleDateRange.start,
          terms: procuredOffer.hotelTerms,
          travelDateRange: procuredOffer.travelDateRange,
        })) ||
      null,
  };
};

export default parseProcuredOffer;
