import sanityClient, { SanityClient } from '@sanity/client';
import { SANITY_DATASET, SANITY_PROJECT_ID } from '@/libs/config/public';
import { SANITY_TOKEN } from '@/libs/config/server';

export const config = {
  projectId: SANITY_PROJECT_ID,
  apiVersion: '2021-05-24',
  dataset: SANITY_DATASET,
  token: SANITY_TOKEN,
  useCdn: true,
};

const client = sanityClient({
  ...config,
});

const previewClient = sanityClient({
  ...config,
  useCdn: false,
});

const getClient = (preview: boolean = false): SanityClient => {
  return preview ? previewClient : client;
};

export default getClient;
