import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { PAY_WITH_OPTIONS, POINTS_STORAGE_KEY } from '@/constants';
import useSessionStorage from '@/hooks/useSessionStorage';
import usePreviousRoute from './hooks/usePreviousRoute';
import { usePaymentType } from '@/components/CustomerSelectionProvider/hooks/usePaymentType';

export const CustomerSelectionContext = React.createContext();

const CustomerSelectionProvider = ({ children }) => {
  const [paymentType, setPaymentType] = usePaymentType();
  const [points, setPoints] = useSessionStorage(POINTS_STORAGE_KEY, {});
  const previousRoute = usePreviousRoute();

  const usePoints =
    paymentType === PAY_WITH_OPTIONS.points ||
    paymentType === PAY_WITH_OPTIONS.pointsPlusPay;
  const usePointsPlusPay = paymentType === PAY_WITH_OPTIONS.pointsPlusPay;

  const value = useMemo(
    () => ({
      previousRoute,
      points,
      setPoints,
      paymentType,
      setPaymentType,
      usePoints,
      usePointsPlusPay,
    }),
    [
      previousRoute,
      points,
      setPoints,
      paymentType,
      setPaymentType,
      usePoints,
      usePointsPlusPay,
    ],
  );

  return (
    <CustomerSelectionContext.Provider value={value}>
      {children}
    </CustomerSelectionContext.Provider>
  );
};

CustomerSelectionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CustomerSelectionProvider;
