import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@qga/roo-ui/components';
import styled from '@emotion/styled';

const StyledBody = styled(Box)`
  overflow-y: auto;
`;

const Body = ({ children }) => <StyledBody p="5">{children}</StyledBody>;

Body.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Body;
