import { isAfter, isBefore } from 'date-fns';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

type UTCDate = { utc: string };

type Message<PageName extends string> = {
  message: any;
  enabled: boolean;
  isSiteWide: boolean;
  pages: Array<PageName> | null;
  start: UTCDate;
  end: UTCDate;
};
const isExcludedPage = (pathname: string, excludedPaths?: string[]) => {
  if (!pathname || !excludedPaths?.length) {
    return false;
  }

  return excludedPaths.includes(pathname);
};

const isMatchingPage = <PageName extends string>(
  pages: PageName[] | null,
  pathname: string,
  pageNameRoutes: Record<PageName, string[]>,
) => {
  if (!pages?.length || !pathname || !pageNameRoutes) {
    return false;
  }

  return pages.some((page) => {
    const matchingRoutes = pageNameRoutes[page];

    if (!matchingRoutes) {
      return false;
    }

    return matchingRoutes.includes(pathname);
  });
};

const isActiveDateRange = (start: UTCDate, end: UTCDate) => {
  if (!start || !end) {
    return false;
  }

  const now = new Date();
  return isAfter(now, new Date(start.utc)) && isBefore(now, new Date(end.utc));
};

const useShowSiteMessage = <PageName extends string>(
  message: Message<PageName> | undefined | null,
  pageNamePaths: Record<PageName, string[]>,
  excludedPaths?: string[],
) => {
  const router = useRouter();
  const pathname = router?.pathname;

  return useMemo(() => {
    if (!message) {
      return false;
    }

    const hasMessage = !!message.message;
    const isEnabled = message.enabled;
    const isDateValid = isActiveDateRange(message.start, message.end);
    const isValidPage =
      !isExcludedPage(pathname, excludedPaths) &&
      (message.isSiteWide ||
        isMatchingPage(message.pages, pathname, pageNamePaths));

    return hasMessage && isEnabled && isDateValid && isValidPage;
  }, [message, pathname, excludedPaths, pageNamePaths]);
};

export default useShowSiteMessage;
