export const createChatEvent = ({ action }: { action: 'start' | 'close' }) => {
  return {
    eventInfo: {
      event: `chat_${action}`,
      event_data: {
        action,
        component_type: 'chat',
        group_name: 'Holidays Sales Chat Support',
      },
    },
    eventOptions: {
      debugMessage: 'GA4 Data Layer Event (Contact Us Chat)',
    },
  };
};
