export const createSearchErrorEvent = ({
  id,
  message,
  searchTerm,
}: {
  id: string;
  message: string;
  searchTerm?: string;
}) => {
  return {
    eventInfo: {
      event: 'search_error',
      event_data: {
        action: 'error',
        component_type: 'search',
        search_term: searchTerm,
        errors: [
          {
            id,
            message,
            name: message,
          },
        ],
      },
    },
    eventOptions: {
      debugMessage: 'GA4 Data Layer Event (Search Error)',
    },
  };
};
