import Boom from '@hapi/boom';
import { includes } from 'ramda';

const ALLOWED_METHOD = [
  'GET',
  'HEAD',
  'POST',
  'PUT',
  'DELETE',
  'CONNECT',
  'OPTIONS',
  'TRACE',
  'PATCH',
];

const withMethod = (method) => (fn) => async (req, res) => {
  const thisMethod = method.toUpperCase();

  if (req.method !== thisMethod || !includes(thisMethod, ALLOWED_METHOD)) {
    const boomified = Boom.methodNotAllowed();
    const { statusCode, payload } = boomified.output;

    res.status(statusCode);
    res.json(payload);

    return;
  }

  await fn(req, res);
};

ALLOWED_METHOD.forEach((method) => {
  withMethod[method] = withMethod(method);
});

export default withMethod;
