import { CreatedEvent } from '../../types';

const PaymentFailedCreditCardError = 'PAYMENT_FAILED_CREDIT_CARD';
const PaymentFailedTravelPassError =
  'PAYMENT_FAILED_QANTAS_GROUP_CREDIT_VOUCHER';
const ERRORS = [PaymentFailedCreditCardError, PaymentFailedTravelPassError];

// We currently only show a message for credit card errors.
const CreditCardErrorMessage =
  'We couldn’t process your payment due to an unexpected error. Please use a different card or try again later.';

export type CheckoutErrorEventArgs = {
  errorCode: string;
};

export const createCheckoutErrorEvent = ({
  errorCode,
}: CheckoutErrorEventArgs): CreatedEvent => {
  const isPaymentError = ERRORS.includes(errorCode);
  return {
    eventInfo: {
      event: 'checkout_error',
      event_data: {
        action: 'error',
        component_type: 'checkout',
      },
      errors: [
        {
          id: errorCode,
          message:
            errorCode === PaymentFailedCreditCardError &&
            CreditCardErrorMessage,
          name: isPaymentError && 'Payment Failed',
        },
      ],
    },
    eventOptions: {
      debugMessage: 'GA4 Data Layer Event (Checkout Error)',
    },
  };
};
