import { CreatedEvent, EcommerceDetailedItem } from '../../types';

export const createCheckoutEvent = ({
  item,
  eventName,
  action,
}: {
  item: EcommerceDetailedItem;
  eventName: string;
  action: string;
}): CreatedEvent => {
  return {
    eventInfo: {
      event: eventName,
      event_data: {
        action,
        component_type: 'checkout',
      },
      ecommerce: {
        value: item.price,
        payment_type: item.payment_type,
        currency: 'AUD',
        items: [item],
      },
    },
    eventOptions: {
      debugMessage: 'GA4 Data Layer Event (Checkout)',
    },
  };
};
