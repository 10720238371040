import React from 'react';
import PropTypes from 'prop-types';
import { Input as BaseInput, Flex } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import { fontWeight, themeGet } from 'styled-system';
import FormErrorMessage from '@/shared/components/FormErrorMessage';

const InputContainer = styled(Flex)`
  :focus-within {
    border-color: ${themeGet('colors.brand.secondary')};
  }
`;

const StyledInput = styled(BaseInput)`
  ${fontWeight}
  &:disabled {
    background-color: ${themeGet('colors.greys.cloud')};
  }
`;

const Input = ({ error, prefix, ...props }) => (
  <>
    {prefix ? (
      <InputContainer
        bg="white"
        color="greys.steel"
        alignItems="center"
        pl="2"
        fontSize={['2xs', null, 'sm']}
        border="2px solid"
        borderColor="white"
        tabIndex={-1}
        width="100%"
      >
        {prefix}
        <StyledInput
          error={!!error}
          mb="0"
          border="none"
          py={[1, null, 3]}
          px={[1, null, 4]}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </InputContainer>
    ) : (
      <StyledInput
        error={!!error}
        mb="0"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    )}
    {error && <FormErrorMessage text={error} />}
  </>
);

Input.defaultProps = {
  error: undefined,
};

Input.propTypes = {
  error: PropTypes.string,
  ...Input.propTypes,
};

export default Input;
