import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import BFF from '@experiences-ui/shared/services/BFF';
import usePreview from '../../hooks/usePreview';
import {
  QH_FEATURE_FLAG_NAMES,
  JQ_FEATURE_FLAG_NAMES,
} from '../../constants/featureFlags';
import { getUserUuid } from '../../utils/localStorage';

interface TreatmentsContext {
  isLoaded: boolean;
  treatments: { [key: string]: { treatment: string } } | null;
}

const Context = createContext<TreatmentsContext>({
  isLoaded: false,
  treatments: null,
});

export const FeatureFlagProvider = ({
  brand,
  children,
}: {
  brand: 'qantas' | 'jetstar';
  children: any;
}) => {
  const [treatments, setTreatments] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const { preview } = usePreview();

  const callGetFeatureFlags = useCallback(
    async (uid: string) => {
      const { data } =
        (await BFF.getFeatureFlags()(
          uid,
          brand === 'qantas' ? QH_FEATURE_FLAG_NAMES : JQ_FEATURE_FLAG_NAMES,
          {
            isInPreview: preview,
          },
        )) || {};
      if (data) {
        setTreatments(data);
        setIsLoaded(true);
      }
    },
    [preview, brand],
  );

  useEffect(() => {
    const localUid = getUserUuid();

    callGetFeatureFlags(localUid);
  }, [callGetFeatureFlags]);

  return (
    <Context.Provider value={{ isLoaded, treatments }}>
      {children}
    </Context.Provider>
  );
};

export const useIsFeatureFlagLoaded = () => {
  const { isLoaded } = useContext(Context);
  return isLoaded;
};

export const useFeatureFlag = (feature: string) => {
  const { treatments } = useContext(Context);
  return treatments?.[feature];
};
