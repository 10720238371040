import UniversalCookie from 'universal-cookie';
import enhanceCookieOptions from '../../utils/enhanceCookieOptions';

class Cookie extends UniversalCookie {
  set(key, value, options) {
    return super.set(key, value, enhanceCookieOptions(options));
  }

  remove(key, options) {
    return super.remove(key, enhanceCookieOptions(options));
  }
}

const cookie = (ctx = {}) =>
  new Cookie(ctx.req ? ctx.req.headers.cookie : undefined);

export default cookie;
