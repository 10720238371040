import { useContext, useEffect } from 'react';
import WebChatContext from '../../WebChatContext';

const useDisableWebChat = () => {
  const webChat = useContext(WebChatContext);

  useEffect(() => {
    if (!webChat) {
      return () => {};
    }

    webChat.disable();

    return () => webChat.enable();
  }, [webChat]);
};

export default useDisableWebChat;
