import React from 'react';
import getFeatureFlag from '../../utils/getFeatureFlag';

const withFeatureFlag = (key) => (Component) => (props) => {
  if (!getFeatureFlag(key)) {
    return null;
  }

  return (
    <Component
      {...props} // eslint-disable-line react/jsx-props-no-spreading
    />
  );
};

export default withFeatureFlag;
