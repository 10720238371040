import { CreatedEvent } from '../../types';

export type UserInteractionArgs = {
  category: string;
  action: string;
  label: string;
};

export const createUserInteractionsEvent = ({
  category,
  action,
  label,
}: UserInteractionArgs): CreatedEvent => {
  return {
    eventInfo: {
      event: 'user_interactions',
      user_event_category: category,
      user_event_action: action,
      user_event_label: label,
    },
    eventOptions: {
      debugMessage: 'GA3 Data Layer Event (User Interaction)',
    },
  };
};
