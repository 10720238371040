import { CreatedEvent, PointsValue } from '../../types';
import { pointsValueToEventValue } from '../../utils';
import differenceInDays from '../../../../../utils/date/differenceInDays';

export type ViewSearchResultsEventArgs = {
  pointsPayment?: PointsValue;
  numResults: number;
  startDate: string;
  endDate: string;
  origin: string;
  destination?: string;
  adults: number | string;
  children: number | string;
  infants: number | string;
};

export const createViewSearchResultsEvent = ({
  pointsPayment,
  numResults,
  startDate,
  endDate,
  origin,
  destination,
  adults,
  children,
  infants,
}: ViewSearchResultsEventArgs): CreatedEvent => {
  const duration =
    differenceInDays(new Date(endDate), new Date(startDate)) || undefined;

  return {
    eventInfo: {
      event: 'view_search_results',
      event_data: {
        action: 'view',
        component_type: 'search_results',
        search_payment_toggle: pointsValueToEventValue(pointsPayment),
        available_package_count: numResults ?? 0,
        start_date: startDate,
        end_date: endDate,
        duration,
        origin,
        destination,
        travellers_adult: adults,
        travellers_children: children,
        travellers_infant: infants,
      },
    },
    eventOptions: {
      debugMessage: 'GA4 Data Layer Event (Search Results)',
    },
  };
};
