import { FC, useState, useEffect, useCallback } from 'react';
import throttle from '../../utils/throttle';

const ESCAPE = 'Escape';

interface Props {
  toggle: FC<Function>;
  content: FC<Function>;
  open?: boolean;
}

const ToggleContent = ({ toggle, content, open = false }: Props) => {
  const [isShown, setIsShown] = useState(open);
  const show = useCallback(() => setIsShown(true), []);
  const hide = useCallback(() => setIsShown(false), []);

  const options = { isShown, show, hide };

  useEffect(() => {
    const handleKeyDown = throttle((event: KeyboardEvent) => {
      if (event.key !== ESCAPE) {
        return;
      }

      setIsShown(false);
    });

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <>
      {toggle(show, options)}
      {isShown && content(hide, options)}
    </>
  );
};

export default ToggleContent;
