export const createMenuEvent = ({
  text,
  index,
  url,
}: {
  text: string;
  index: number;
  url: string;
}) => {
  return {
    eventInfo: {
      event: 'menu_click',
      event_data: {
        action: 'click',
        component_type: 'menu',
        item_text: text,
        index,
        url,
      },
    },
    eventOptions: {
      debugMessage: 'GA4 Data Layer Event (Menu Click)',
    },
  };
};
