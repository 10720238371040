import useOAuth, { AUTH_ROUTE } from '../../../../utils/oauth';
import { AuthStrategy, Member, AUTH_STRATEGY } from '../../types';
import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

const useAuth = ({ authStrategy }: { authStrategy: AuthStrategy }) => {
  const oAuth = useOAuth();
  const [memberDetails, setMemberDetails] = useState<Member | null>(null);
  const [isMemberDetailsReady, setIsMemberDetailsReady] = useState(false);
  const { pathname } = useRouter();

  useEffect(() => {
    if (authStrategy === AUTH_STRATEGY.OAUTH) {
      setMemberDetails(oAuth?.member);
    }
    return;
  }, [authStrategy, oAuth?.member]);

  // Fetch member details on page load if not already fetched and not on /auth route.
  useEffect(() => {
    if (
      authStrategy === AUTH_STRATEGY.OAUTH &&
      !memberDetails &&
      !isMemberDetailsReady &&
      pathname !== AUTH_ROUTE
    ) {
      oAuth.getMember().then(() => setIsMemberDetailsReady(true));
    }
  }, [memberDetails, authStrategy, pathname, isMemberDetailsReady, oAuth]);

  const login = useCallback(() => {
    if (authStrategy === AUTH_STRATEGY.OAUTH) {
      oAuth.login();
    }
  }, [authStrategy, oAuth]);

  const logout = useCallback(async () => {
    if (authStrategy === AUTH_STRATEGY.OAUTH) {
      await oAuth.logout();
    }
    setMemberDetails(null);
  }, [authStrategy, oAuth]);

  const getMemberDetails = useCallback(async () => {
    await oAuth.getMember();
    setIsMemberDetailsReady(true);
  }, [oAuth]);

  return {
    logout,
    login,
    memberDetails,
    isMemberDetailsReady,
    getMemberDetails,
  };
};

export default useAuth;
