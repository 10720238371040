export const SYD = 'SYD';
export const MEL = 'MEL';

export const MAJOR_CITIES = [
  { name: 'Adelaide', code: 'ADL' },
  { name: 'Canberra', code: 'CBR' },
  { name: 'Sydney', code: 'SYD' },
  { name: 'Darwin', code: 'DRW' },
  { name: 'Brisbane', code: 'BNE' },
  { name: 'Hobart', code: 'HBA' },
  { name: 'Melbourne', code: 'MEL' },
  { name: 'Perth', code: 'PER' },
];

const ORIGINS = [
  ...MAJOR_CITIES,
  { name: 'Proserpine', code: 'PPP' },
  { name: 'Alice Springs', code: 'ASP' },
  { name: 'Gold Coast', code: 'OOL' },
  { name: 'Kangaroo Island', code: 'KGC' },
  { name: 'Port Lincoln', code: 'PLO' },
  { name: 'Whyalla', code: 'WYA' },
  { name: 'Devonport', code: 'DPO' },
  { name: 'Mildura', code: 'MQL' },
  { name: 'Cairns', code: 'CNS' },
  { name: 'Geraldton', code: 'GET' },
  { name: 'Kalgoorlie', code: 'KGI' },
  { name: 'Karratha', code: 'KTA' },
  { name: 'Learmouth', code: 'LEA' },
  { name: 'Port Headland', code: 'PHE' },
  { name: 'Bundaberg', code: 'BDB' },
  { name: 'Coffs Harbour', code: 'CFS' },
  { name: 'Emerald', code: 'EMD' },
  { name: 'Gladstone', code: 'GLT' },
  { name: 'Hervey Bay', code: 'HVB' },
  { name: 'Mount Isa', code: 'ISA' },
  { name: 'Longreach', code: 'LRE' },
  { name: 'Mackay', code: 'MKY' },
  { name: 'Newcastle', code: 'NTL' },
  { name: 'Port Macquarie', code: 'PQQ' },
  { name: 'Rockhampton', code: 'ROK' },
  { name: 'Tamworth', code: 'TMW' },
  { name: 'Townsville', code: 'TSV' },
  { name: 'Albury', code: 'ABX' },
  { name: 'Armidale', code: 'ARM' },
  { name: 'Bendigo', code: 'BXG' },
  { name: 'Dubbo', code: 'DBO' },
  { name: 'Merimbula', code: 'MIM' },
  { name: 'Moree', code: 'MRZ' },
  { name: 'Orange', code: 'OAG' },
  { name: 'Wagga Wagga', code: 'WGA' },
  { name: 'Launceston', code: 'LST' },
  { name: 'Hamilton Island', code: 'HTI' },
  { name: 'Broome', code: 'BME' },
  { name: 'Norfolk Island', code: 'NLK' },
  { name: 'Ayers Rock', code: 'AYQ' },
];

export default ORIGINS;
