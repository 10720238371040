import { css, Global } from '@emotion/core';
import React from 'react';
import { ASSET_PREFIX } from '@/config/public';

const Ciutadella = () => (
  <Global
    styles={css`
      @font-face {
        font-family: 'Ciutadella';
        src:
          url(${`${ASSET_PREFIX}/fonts/CiutadellaRegular.woff2`})
            format('woff2'),
          url(${`${ASSET_PREFIX}/fonts/CiutadellaRegular.woff`}) format('woff'),
          url(${`${ASSET_PREFIX}/fonts/CiutadellaRegular.ttf`})
            format('truetype');
        font-style: normal;
        font-weight: 400;
        text-rendering: optimizeLegibility;
        font-display: swap;
      }

      @font-face {
        font-family: 'Ciutadella';
        src:
          url(${`${ASSET_PREFIX}/fonts/CiutadellaMedium.woff2`}) format('woff2'),
          url(${`${ASSET_PREFIX}/fonts/CiutadellaMedium.woff`}) format('woff'),
          url(${`${ASSET_PREFIX}/fonts/CiutadellaMedium.ttf`})
            format('truetype');
        font-style: normal;
        font-weight: 600;
        text-rendering: optimizeLegibility;
        font-display: swap;
      }
    `}
  />
);

export default Ciutadella;
