import { CreatedEvent, EcommercePurchaseItem } from '../../types';

export const createPurchaseEvent = ({
  item,
  id,
  voucherValue,
  pointsEarned,
  tax,
  coupon,
}: {
  item: EcommercePurchaseItem;
  id: string;
  voucherValue: string | number;
  pointsEarned: number | undefined | null;
  tax: number;
  coupon: string | undefined | null;
}): CreatedEvent => {
  const pointsPercentage =
    item.points_used && item.points_value
      ? (item.points_used / item.points_value) * 100
      : 0;
  return {
    eventInfo: {
      event: 'purchase',
      event_data: {
        action: 'purchase',
        component_type: 'checkout',
      },
      ecommerce: {
        transaction_id: id,
        value: item.price,
        payment_type: item.payment_type,
        currency: 'AUD',
        shipping: 0,
        total_points_used_percentage: pointsPercentage,
        total_cash_used: item.cash_used,
        total_points_used: item.points_used,
        total_points_value: item.points_value,
        total_points_earned: pointsEarned,
        voucher_value: Number(voucherValue) || 0,
        discount_value: Number(voucherValue) || 0,
        voucher_used: Number(voucherValue) > 0,
        coupon,
        tax,
        items: [item],
      },
    },
    eventOptions: {
      debugMessage: 'GA4 Data Layer Event (Purchase)',
    },
  };
};
