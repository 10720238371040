import campaign from '@/libs/sanity/queries/campaign';
import procuredOffer from '@/libs/sanity/queries/procuredOffer';
import { SANITY_QUERY_BRAND } from '@/libs/config/server';

const DRAFT_FILTER_QUERY =
  /(&&)?\s?!\(_id in path\("drafts\.\*\*"\)\)\s?(&&)?/g;

const draftableQuery = (query) => query.replace(DRAFT_FILTER_QUERY, '');
const PARAMS = { BRAND: SANITY_QUERY_BRAND };

const buildFetcher = (client, preview) => {
  const fetch = (query, params) =>
    client.fetch(preview ? draftableQuery(query) : query, {
      ...PARAMS,
      ...params,
    });

  const getCampaign = async (params) => (await fetch(campaign, params))[0];
  const getProcuredOffer = (params) => fetch(procuredOffer, params);

  return {
    getCampaign,
    getProcuredOffer,
  };
};

export default buildFetcher;
