import styled from '@emotion/styled';
import { Link as RooLink } from '@qga/roo-ui/components';
import { color, fontWeight, space, themeGet } from 'styled-system';
import withProps from '@/components/withProps';
import Button from '@/components/Button';
import withLink, {
  LinkButtonProps,
  LinkNakedProps,
  LinkTextProps,
  LinkTextVariants,
} from './withLink';

const ButtonLink = withProps({
  as: 'a',
})(Button);

const NakedLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: inherit;

  ${color}
  ${fontWeight} 
  ${space}
`;

const CoverLink = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const StyledLink = styled(RooLink)<{ variant: LinkTextVariants }>`
  color: ${({ variant }: { variant: LinkTextVariants }) =>
    variant === 'light'
      ? themeGet('colors.white')
      : themeGet('colors.ui.link')};
  &:hover,
  &:focus {
    color: ${({ variant }: { variant: LinkTextVariants }) =>
      variant === 'light'
        ? themeGet('colors.white')
        : themeGet('colors.ui.linkHover')};
  }
`;

const Link = {
  Text: withLink<LinkTextProps>(StyledLink),
  Button: withLink<LinkButtonProps>(ButtonLink),
  Naked: withLink<LinkNakedProps>(NakedLink),
  Cover: withLink(CoverLink),
};

export default Link;
