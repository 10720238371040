export const createTileClickEvent = ({
  groupName,
  itemName,
  itemText,
  index,
  url,
}: {
  groupName: string;
  itemName?: string;
  itemText: string;
  index?: number;
  url?: string;
}) => {
  return {
    eventInfo: {
      event: 'tile_click',
      event_data: {
        action: 'click',
        component_type: 'tile',
        group_name: groupName,
        item_name: itemName,
        item_text: itemText,
        index,
        url,
      },
    },
    eventOptions: {
      debugMessage: 'GA4 Data Layer Event (Tile Click)',
    },
  };
};
