import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@qga/roo-ui/components';

const Footer = ({ children }) => (
  <Box p="5" borderTop="1" borderColor="greys.alto">
    {children}
  </Box>
);

Footer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Footer;
