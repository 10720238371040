import React from 'react';
import { Member } from './types';

const AuthContext = React.createContext<{
  logout: () => void;
  login: () => void;
  memberDetails: Member | null;
  getMemberDetails: () => void;
  isMemberDetailsReady: boolean;
}>({
  logout: () => undefined,
  login: () => undefined,
  memberDetails: null,
  getMemberDetails: () => undefined,
  isMemberDetailsReady: false,
});

export default AuthContext;
