import { v4 as uuidv4 } from 'uuid';

const USER_ID_KEY = 'HOLIDAYS_USER_UUID';

export const getUserUuid = () => {
  let uuid = localStorage.getItem(USER_ID_KEY);

  if (!uuid) {
    uuid = uuidv4();
    localStorage.setItem(USER_ID_KEY, uuid);
  }

  return uuid;
};
