import PropTypes from 'prop-types';
import React from 'react';
import { ThemeProvider } from '@qga/roo-ui';
import theme from '../../lib/theme';
import CustomerSelectionProvider from '../CustomerSelectionProvider';
import { ProgressBarProvider } from '../ProgressBarContext';
import { UnexpectedErrorModalProvider } from '../UnexpectedErrorModal';
import SessionStorageProvider from '@experiences-ui/shared/v2/components/SessionStorageContext';
import { FeatureFlagProvider } from '@experiences-ui/shared/v2/contexts/featureFlags';
import { OptimizelyWrapperProvider } from '@experiences-ui/shared/v2/contexts/optimizely';

const Providers = ({ children }) => (
  <ThemeProvider theme={theme}>
    <CustomerSelectionProvider>
      <SessionStorageProvider>
        <ProgressBarProvider>
          <FeatureFlagProvider brand="qantas">
            <OptimizelyWrapperProvider>
              <UnexpectedErrorModalProvider>
                {children}
              </UnexpectedErrorModalProvider>
            </OptimizelyWrapperProvider>
          </FeatureFlagProvider>
        </ProgressBarProvider>
      </SessionStorageProvider>
    </CustomerSelectionProvider>
  </ThemeProvider>
);

Providers.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Providers;
