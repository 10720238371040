import { useSessionStorage } from 'react-use';
import { generateState } from './state';
import {
  SessionStorage,
  SessionStorageDefaultValue,
} from '../../../constants/storage';

export const useOAuthState = () => {
  return useSessionStorage(
    SessionStorage.OAUTH_SESSION_KEY,
    typeof window !== 'undefined'
      ? generateState()
      : SessionStorageDefaultValue.OAUTH_SESSION_KEY,
    true,
  );
};
