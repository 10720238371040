import { ORIGINS } from '@/constants';

const useFlyingFromOptions = () => [
  {
    value: '',
    text: 'Please select',
  },
  ...ORIGINS.map((m) => ({
    value: m.code,
    text: m.name,
  })),
  {
    value: 'other',
    text: 'Other',
  },
];

export default useFlyingFromOptions;
