export const parse = (value) => (value ? Number(value) : 0);

const mapOccupants = (input) => {
  const { adults, children, infants } = input || {};

  return {
    adults: parse(adults),
    children: parse(children),
    infants: parse(infants),
  };
};

export default mapOccupants;
