import { CreatedEvent } from '../../types';

export type ViewPromotionEventArgs = {
  name: string;
};

export const createViewPromotionEvent = ({
  name,
}: ViewPromotionEventArgs): CreatedEvent => ({
  eventInfo: {
    event: 'view_promotion',
    event_data: {
      action: 'view',
      component_type: 'promotion_list',
    },
    ecommerce: {
      items: [
        {
          promotion_name: name,
          creative_slot: 'header_banner',
        },
      ],
    },
  },
  eventOptions: {
    debugMessage: 'GA4 Data Layer Event (View Promotion)',
  },
});
