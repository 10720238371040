import Script from 'next/script';
import PropTypes from 'prop-types';
import React from 'react';
import { PURE_CLOUD_BUNDLE_URL } from '../../../../config/public';
import getPureCloudWebChat from './getPureCloudWebChat';

const Register = ({ onRegister }) => (
  <Script
    src={PURE_CLOUD_BUNDLE_URL}
    strategy="lazyOnload"
    onLoad={() => {
      const pureCloudWebChat = getPureCloudWebChat();

      if (!pureCloudWebChat) {
        return;
      }

      pureCloudWebChat.ready();

      pureCloudWebChat.subscribe('CoBrowse.ready', () => {
        onRegister(pureCloudWebChat);
      });
    }}
  />
);

Register.propTypes = {
  onRegister: PropTypes.func.isRequired,
};

export default Register;
