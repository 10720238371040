export const BASE_PATH = process.env.BASE_PATH || '';
export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL || '';
export const NODE_ENV = process.env.NODE_ENV;
export const RUNTIME_ENV = process.env.RUNTIME_ENV;

export const BUILD_ID = process.env.BUILD_ID;
export const ENVIRONMENT = process.env.ENVIRONMENT;
export const ASSET_PREFIX = process.env.ASSET_PREFIX;

export const RELEASE = `qp-${BUILD_ID}-${ENVIRONMENT}`;

export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
export const GTM_AUTH = process.env.NEXT_PUBLIC_GTM_AUTH;
export const GTM_PREVIEW = process.env.NEXT_PUBLIC_GTM_PREVIEW;

export const GTM_DEBUG_EVENTS = process.env.NEXT_PUBLIC_GTM_DEBUG_EVENTS;

export const PURE_CLOUD_BUNDLE_URL =
  process.env.NEXT_PUBLIC_PURE_CLOUD_BUNDLE_URL;
export const PURE_CLOUD_DEPLOYMENT_KEY =
  process.env.NEXT_PUBLIC_PURE_CLOUD_DEPLOYMENT_KEY;
export const PURE_CLOUD_ORG_GUID = process.env.NEXT_PUBLIC_PURE_CLOUD_ORG_GUID;
export const PURE_CLOUD_QUEUE_NAME =
  process.env.NEXT_PUBLIC_PURE_CLOUD_QUEUE_NAME;

export const MASTERCARD_GATEWAY_MERCHANT_ID =
  process.env.NEXT_PUBLIC_MASTERCARD_GATEWAY_MERCHANT_ID;
export const MASTERCARD_GATEWAY_VERSION =
  process.env.NEXT_PUBLIC_MASTERCARD_GATEWAY_VERSION;
export const MASTERCARD_GATEWAY_API_URL =
  process.env.NEXT_PUBLIC_MASTERCARD_GATEWAY_API_URL;

export const SNARE_JS_URL = process.env.NEXT_PUBLIC_SNARE_JS_URL;

export const GOOGLE_MAPS_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY;
export const GOOGLE_MAPS_STATIC_API_URL =
  process.env.NEXT_PUBLIC_GOOGLE_MAPS_STATIC_API_URL;

export const SIMPLICITY_JS_URL = process.env.NEXT_PUBLIC_SIMPLICITY_JS_URL;

export const ADYEN_JS_URL = process.env.NEXT_PUBLIC_ADYEN_JS_URL;
export const AYDEN_ENVIRONMENT = process.env.NEXT_PUBLIC_AYDEN_ENVIRONMENT;
export const ADYEN_QEPG_CLIENT_KEY =
  process.env.NEXT_PUBLIC_ADYEN_QEPG_CLIENT_KEY;

export const BOOKING_SURVEY_URL = process.env.NEXT_PUBLIC_BOOKING_SURVEY_URL;

export const SANITY_PROJECT_ID = process.env.NEXT_PUBLIC_SANITY_PROJECT_ID;
export const SANITY_DATASET = process.env.NEXT_PUBLIC_SANITY_DATASET;

export const X_SELL_BANNER_URL = process.env.NEXT_PUBLIC_X_SELL_BANNER_URL;

export const QANTAS_INSURANCE_URL =
  process.env.NEXT_PUBLIC_QANTAS_INSURANCE_URL;

export const AUTH_API_URL = process.env.NEXT_PUBLIC_AUTH_API_URL;
export const LSL_AUTH_URL = process.env.NEXT_PUBLIC_LSL_AUTH_URL;
export const LSL_USERNAME = process.env.NEXT_PUBLIC_LSL_USERNAME;
export const REDIRECT_URL = `${process.env.NEXT_PUBLIC_BASE_URL}${BASE_PATH}`;

export const BUILDKITE_INFO = {
  BUILDKITE_BUILD_AUTHOR: process.env.BUILDKITE_BUILD_AUTHOR,
  BUILDKITE_BUILD_URL: process.env.BUILDKITE_BUILD_URL,
  BUILDKITE_BUILD_NUMBER: process.env.BUILDKITE_BUILD_NUMBER,
  BUILDKITE_BRANCH: process.env.BUILDKITE_BRANCH,
  BUILDKITE_COMMIT: process.env.BUILDKITE_COMMIT,
  BUILD_TIME: process.env.BUILD_TIME,
  ENVIRONMENT,
};
