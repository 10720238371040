import {
  addDays,
  isWeekend,
  isSameDay,
  format,
  formatIsoDate,
} from '@/lib/date';

const getNextWorkingDate = (input) => {
  let date = input;
  let workingDate;

  while (!workingDate) {
    if (isWeekend(date)) {
      date = addDays(date, 1);
    } else {
      workingDate = date;
    }
  }

  return workingDate;
};

const useDateOptions = () => {
  const dates = [getNextWorkingDate(new Date())];

  while (dates.length < 4) {
    const date = dates[dates.length - 1];
    const nextWorkingDate = getNextWorkingDate(addDays(date, 1));

    dates.push(nextWorkingDate);
  }

  return [
    {
      value: '',
      text: 'Please select',
    },
    ...dates.map((d) => {
      const value = formatIsoDate(
        new Date(d.getFullYear(), d.getMonth(), d.getDate()),
      );
      const text = format('E dd LLL')(d);

      if (isSameDay(d, new Date())) {
        return {
          value,
          text: `Today - ${text}`,
        };
      }

      return {
        value,
        text,
      };
    }),
  ];
};

export default useDateOptions;
