export const createGalleryOpenEvent = ({
  groupName,
}: {
  groupName: string;
}) => {
  return {
    eventInfo: {
      event: 'gallery_open',
      event_data: {
        action: 'open',
        component_type: 'gallery',
        group_name: `${groupName} Gallery`,
      },
    },
    eventOptions: {
      debugMessage: 'GA4 Data Layer Event (Gallery Open)',
    },
  };
};
