import { Icon } from '@qga/roo-ui/components';
import type { AlertBannerVariants } from '@/v2/components/ui/AlertBanner/AlertBanner';

const AlertIcon = ({
  variant,
  iconColor,
}: {
  variant: AlertBannerVariants;
  iconColor?: string;
}) => {
  switch (variant) {
    case 'warning':
      return (
        <Icon
          data-testid="ALERT_BANNER_ICON"
          name="warningOutline"
          color={iconColor}
        />
      );
    case 'warningFill':
      return (
        <Icon
          data-testid="ALERT_BANNER_ICON"
          name="warning"
          color={iconColor}
        />
      );
    case 'info':
      return (
        <Icon data-testid="INFO_BANNER_ICON" name="info" color={iconColor} />
      );
    default:
      return null;
  }
};

export default AlertIcon;
