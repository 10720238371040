import { CreatedEvent } from '../../types';

export type PageViewGA3Args = {
  event: string;
  title: string;
  type: string;
};

export const createPageViewEvent = ({
  event,
  title,
  type,
}: PageViewGA3Args): CreatedEvent => {
  return {
    eventInfo: {
      event,
      page_title: title,
      page_type: type,
    },
    eventOptions: {
      debugMessage: 'GA3 Data Layer Event (Page View)',
    },
  };
};
