import React from 'react';
import fancyIconChatBubble from './assets/fancy-icon-chat-bubble.svg';
import FloatingEnquire from './components/FloatingEnquire';
import RequestCallback from './components/RequestCallback';
import useWebChat from './hooks/useWebChat';
import PropTypes from 'prop-types';

const WebChat = ({ hasStickyFooter }) => {
  const webChat = useWebChat();

  if (!webChat || !webChat.enabled || webChat.opened) {
    return null;
  }

  if (webChat.offline) {
    return <RequestCallback hasStickyFooter={hasStickyFooter} />;
  }

  return (
    <FloatingEnquire
      image={fancyIconChatBubble}
      onClick={webChat.open}
      disabled={webChat.opening}
      hasStickyFooter={hasStickyFooter}
    >
      Chat to a holiday expert
    </FloatingEnquire>
  );
};

WebChat.defaultProps = {
  hasStickyFooter: false,
};

WebChat.propTypes = {
  hasStickyFooter: PropTypes.bool,
};

export default WebChat;
