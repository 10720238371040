import PropTypes from 'prop-types';
import React from 'react';
import { Alert } from '@qga/roo-ui/components';
import Button from '@/components/Button';

const Requested = ({ onClose }) => (
  <>
    <Alert.success>
      <strong>Your contact details have been sent</strong>
      <br />
      Thank your for your interest. We&apos;ve got your contact details and will
      be in touch shortly.
    </Alert.success>
    <Button
      width={['100%', null, 'auto']}
      outline
      variant="primary"
      onClick={onClose}
    >
      CLOSE
    </Button>
  </>
);

Requested.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Requested;
