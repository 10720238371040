import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Modal from '@/components/Modal';
import { CREATE_CALLBACK_TYPE } from '@/constants';
import BFF from '@/services/BFF';
import Form from './components/Form';
import Requested from './components/Requested';

const RequestCallbackModal = ({ onClose }) => {
  const [requested, setRequested] = useState(false);

  const handleSubmit = (data) => {
    const { phoneNumber, scheduledTime, ...rest } = data;

    BFF.createCallback()({
      type: CREATE_CALLBACK_TYPE.REQUEST_CALL_BACK,
      phoneNumber,
      scheduledTime,
      data: rest,
    }).then(() => setRequested(true));
  };

  return (
    <Modal onClose={onClose}>
      <Modal.Header>Request callback</Modal.Header>
      <Modal.Body>
        {requested ? (
          <Requested onClose={onClose} />
        ) : (
          <Form onSubmit={handleSubmit} />
        )}
      </Modal.Body>
    </Modal>
  );
};

RequestCallbackModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default RequestCallbackModal;
