import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Button } from '@qga/roo-ui/components';
import { variant, themeGet } from 'styled-system';

const buttonStyle = variant({ key: 'buttons' });

const getSecondaryColors = (props) => {
  return buttonStyle(props) || {};
};

export default styled(Button)`
  ${(props) => {
    if (!props.outline) {
      return null;
    }

    const secondaryColorText = getSecondaryColors(props).color;
    const secondaryColorBg = getSecondaryColors(props).backgroundColor;

    return css`
      color: ${secondaryColorBg};
      border: 2px solid ${secondaryColorBg};
      background: transparent;
      -webkit-transition-property: none;
      -moz-transition-property: none;
      -o-transition-property: none;
      transition-property: none;

      &:hover {
        background: ${secondaryColorBg};
        border-color: ${secondaryColorBg};
        color: ${secondaryColorText};
      }
    `;
  }}

  ${(props) =>
    ({
      sm: css`
        font-size: ${themeGet('fontSizes.sm')(props)};
        padding: ${themeGet('space.2')(props)} ${themeGet('space.3')(props)};
      `,
    })[props.size]}
`;
