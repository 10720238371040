import React from 'react';
import PropTypes from 'prop-types';
import RequestCallbackModal from '@/components/RequestCallbackModal';
import ToggleContent from '@experiences-ui/shared/v2/components/ToggleContent';
import withFeatureFlag from '@/components/withFeatureFlag';

import FloatingEnquire from '../FloatingEnquire';
import fancyIconCallback from './assets/fancy-icon-callback.svg';

const RequestCallback = ({ hasStickyFooter }) => (
  <ToggleContent
    toggle={(open) => (
      <FloatingEnquire
        image={fancyIconCallback}
        onClick={open}
        hasStickyFooter={hasStickyFooter}
      >
        Request callback
      </FloatingEnquire>
    )}
    content={(close) => <RequestCallbackModal onClose={close} />}
  />
);

RequestCallback.defaultProps = {
  hasStickyFooter: false,
};

RequestCallback.propTypes = {
  hasStickyFooter: PropTypes.bool,
};

export default withFeatureFlag('REQUEST_CALLBACK')(RequestCallback);
