import { useCallback, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import throttle from '@experiences-ui/shared/v2/utils/throttle';

const useSize = () => {
  const elementObserverRef = useRef();
  const handleResizeRef = useRef();
  const [size, setSize] = useState({
    height: 0,
    width: 0,
  });

  const ref = useCallback((element) => {
    if (!element) {
      const handleResize = handleResizeRef.current;
      window.removeEventListener('resize', handleResize);
      elementObserverRef.current.disconnect();

      return;
    }

    const handleResize = throttle(() =>
      setSize({
        height: element.offsetHeight,
        width: element.offsetWidth,
      }),
    );

    handleResize();
    window.addEventListener('resize', handleResize);

    if (!elementObserverRef.current) {
      const elementObserver = new ResizeObserver(handleResize);
      elementObserver.observe(element);

      elementObserverRef.current = elementObserver;
    }

    handleResizeRef.current = handleResize;
  }, []);

  return {
    ...size,
    ref,
  };
};

export default useSize;
