import { theme as qantas } from '@qga/roo-ui';
import { rem } from 'polished';
import * as icons from './icons';

export const BREAK_POINTS_PX = [768, 1100, 1280];

const fontSizes = {
  '2xs': qantas.fontSizes.xs,
  xs: qantas.fontSizes.sm,
  sm: qantas.fontSizes.base,
  base: qantas.fontSizes.md,
  md: qantas.fontSizes.lg,
  lg: qantas.fontSizes.xl,
  xl: qantas.fontSizes['2xl'],
  '2xl': rem('32px'),
  '3xl': qantas.fontSizes['3xl'],
  '4xl': qantas.fontSizes['4xl'],
};

const colors = {
  ...qantas.colors,
  darkGreen: '#0F7401',
  greys: {
    ...qantas.colors.greys,
    emperor: '#555555',
    smoke: '#DEDEDE',
    cloud: '#F6F6F6',
  },
  secondaryDark: '#7ED2D0',
  secondaryLighter: '#E1FAF9',
  primaryDarker: '#B10000',
  background: {
    card: qantas.colors.white,
    backdrop: qantas.colors.greys.charcoal,
  },
  alert: {
    warning: '#FFEAC4',
    warningDark: '#F5A623',
    info: '#E1FAF9',
  },
};

const dividerStyles = {
  vertical: {
    borderTop: 0,
    borderLeft: qantas.borders[1],
    height: 'auto',
    width: '1px',
  },
  horizontal: {
    borderTop: qantas.borders[1],
    borderLeft: 0,
    height: '1px',
    width: 'auto',
  },
};

const textStyles = {
  caps: {
    textTransform: 'uppercase',
    fontWeight: qantas.fontWeights.bold,
  },
  summary: {
    textTransform: 'uppercase',
    fontSize: fontSizes.sm,
    fontWeight: qantas.fontWeights.bold,
    letterSpacing: '0.5px',
  },
  h1: {
    fontSize: fontSizes['3xl'],
    fontWeight: qantas.fontWeights.bold,
    lineHeight: qantas.lineHeights.normal,
  },
  h2: {
    fontSize: fontSizes['2xl'],
    fontWeight: qantas.fontWeights.bold,
    lineHeight: qantas.lineHeights.normal,
  },
  h3: {
    fontSize: fontSizes.xl,
    fontWeight: qantas.fontWeights.bold,
    lineHeight: qantas.lineHeights.loose,
  },
  h4: {
    fontSize: fontSizes.lg,
    fontWeight: qantas.fontWeights.bold,
    lineHeight: qantas.lineHeights.loose,
  },
  h5: {
    fontSize: fontSizes.md,
    fontWeight: qantas.fontWeights.bold,
    lineHeight: qantas.lineHeights.loose,
  },
  h6: {
    fontSize: fontSizes.base,
    fontWeight: qantas.fontWeights.bold,
    lineHeight: qantas.lineHeights.loose,
  },
  label: {
    fontSize: fontSizes.sm,
    lineHeight: qantas.lineHeights.loose,
    fontWeight: qantas.fontWeights.bold,
    color: colors.greys.charcoal,
  },
  text: {
    fontSize: fontSizes.base,
    lineHeight: qantas.lineHeights.loose,
    fontWeight: qantas.fontWeights.normal,
  },
};

const shadows = {
  soft: '0 1px 20px rgba(0, 0, 0, 0.15)',
  hard: '0 1px 2px 0 rgba(0, 0, 0, 0.2);',
  modal: '0 4px 10px 0 rgba(0, 0, 0, 0.1)',
  result: '0 5px 10px 0 rgba(0, 0, 0, 0.1)',
  footer: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
  light: '0 1px 1px 0 rgba(0, 0, 0, 0.1)',
  // As per Foundations 1.0 - Please use these where appropriate instead of the above
  foundationCard: '0 1px 1px 0 rgba(0, 0, 0, 0.1)',
  foundationPrimary: '0 1px 2px 0 rgba(0, 0, 0, 0.2)',
  foundationSecondary: '0 1px 1px 0 rgba(0, 0, 0, 0.2)',
  foundationFloating: '0 1px 15px 0 rgba(0, 0, 0, 0.1)',
  foundationSoft: '0 15px 20px 0 rgba(0, 0, 0, 0.02)',
};

const components = {
  panel: {
    backgroundColor: {
      selected: colors.greys.cloud,
      default: colors.white,
    },
  },
  packageOption: {
    width: '404px',
    padding: '24px',
    travelAnytime: {
      lineHeight: '30px',
      gap: '4px',
    },
    default: {
      borderColor: colors.greys.smoke,
    },
    selected: {
      borderColor: colors.brand.primary,
    },
    borderWidth: '2px',
    borderRadius: '8px',
    pillBackgroundColor: colors.bayBlue30,
  },
  dateRangePicker: {
    disabledMonthNav: colors.greys.dusty,
    monthNavHover: colors.brand.primary,
    daysOfWeek: {
      borderColor: colors.greys.smoke,
    },
    monthName: {
      color: colors.greys.mineShaft,
      fontSize: fontSizes.md,
      textTransform: 'uppercase',
    },
    weekName: {
      color: colors.greys.charcoal,
      fontSize: fontSizes.sm,
    },
    day: {
      borderRadius: qantas.radii.default,
      borderWidth: 0,
      fontSize: fontSizes.base,
      fontWeight: 500,
    },
    daySelected: {
      color: 'inherit',
      background: colors.brand.secondary,
    },
    potentialDaySelected: {
      borderColor: colors.secondaryDark,
      borderWidth: '1px',
    },
    rangeSelected: {
      background: colors.secondaryLighter,
    },
    unavailableDay: {
      color: colors.greys.dusty,
    },
    tooltip: {
      top: '-40px',
    },
    loadMoreButton: {
      paddingX: qantas.space[8],
      paddingY: qantas.space[3],
      borderRadius: qantas.radii.default,
      borderColor: colors.ui.link,
      borderColorHover: colors.ui.linkHover,
      color: colors.ui.link,
      colorHover: colors.ui.linkHover,
      disabled: colors.greys.dusty,
      backgroundHover: 'transparent',
      fontSize: fontSizes.xs,
      fontWeight: 700,
      lineHeight: '1.25rem',
      textTransform: 'uppercase',
    },
  },
  travelDatesCalendar: {
    background: colors.white,
    borderColor: colors.greys.smoke,
    closeButton: {
      padding: qantas.space[3],
    },
    actions: {
      borderColor: colors.greys.smoke,
      borderWidth: '1px',
    },
    actionConfirm: {
      paddingX: qantas.space[8],
      paddingY: qantas.space[3],
      width: '7.5rem',
      borderRadius: qantas.radii.default,
      background: colors.ui.link,
      backgroundHover: colors.ui.linkHover,
      color: colors.white,
      colorHover: colors.white,
      fontSize: fontSizes.xs,
      fontWeight: 700,
      lineHeight: '1.25rem',
    },
    actionReset: {
      color: colors.ui.link,
      colorHover: colors.ui.linkHover,
      fontSize: fontSizes.sm,
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    selectText: {
      color: colors.greys.steel,
    },
  },
  select: {
    fullScreenMenu: {
      borderRadius: 0,
    },
    icon: {
      size: 24,
    },
    scrollable: {
      padding: qantas.space[4],
      gap: qantas.space[2],
      mobile: {
        padding: qantas.space[5],
        gap: qantas.space[6],
      },
    },
    menuLabel: {
      fontWeight: 'inherit',
      fontSize: fontSizes.md,
      lineHeight: 'inherit',
      color: 'inherit',
      textAlign: 'center',
    },
    menuItem: {
      color: 'black',
      backgroundHover: colors.greys.cloud,
      backgroundActive: colors.greys.cloud,
      borderLeftColorActive: colors.ui.info,
      paddingY: qantas.space[2],
      paddingX: qantas.space[3],
      fontWeightHover: 600,
      fontSize: fontSizes.base,
      lineHeight: '1.5',
    },
    size: {
      md: {
        height: '48px',
        fontSize: fontSizes.base,
        paddingLeft: '20px',
        paddingRight: '20px',
        mobile: {
          height: '48px',
          fontSize: fontSizes.base,
          paddingLeft: '20px',
          paddingRight: '20px',
        },
      },
    },
    backgroundHover: colors.ui.popup,
    background: colors.white,
    borderColorActive: colors.brand.primary,
    borderColor: colors.greys.smoke,
    borderColorError: colors.ui.error,
    borderActive: '2px solid',
    border: '2px solid',
    borderRadius: '26px',
    colorHover: colors.brand.primary,
    color: colors.greys.mineShaft,
    marginActive: '0',
    margin: '0',
    fontWeight: 400,
    fontWeightHover: 600,
    fontSize: fontSizes.base,
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  collapsiblePackageSection: {
    gap: {
      column: '16px',
      row: '24px',
      mobile: {
        column: '16px',
        row: '24px',
      },
    },
    padding: {
      vertical: '32px',
      mobile: {
        vertical: '32px',
      },
    },
    background: {
      closed: colors.white,
      open: colors.greys.cloud,
    },
    heading: {
      big: {
        fontFamily: qantas.fontFamily,
        fontSize: fontSizes.xl,
        lineHeight: '40px',
        fontWeight: 'bold',
        color: colors.greys.charcoal,
        mobile: {
          fontSize: fontSizes.xl,
          lineHeight: '40px',
        },
      },
      small: {
        fontFamily: qantas.fontFamily,
        fontSize: fontSizes.base,
        lineHeight: '1.5',
        fontWeight: 'normal',
        color: colors.greys.charcoal,
        mobile: {
          fontSize: fontSizes.base,
          lineHeight: '1.5',
        },
      },
      gap: '4px',
    },
  },
  selectableContainer: {
    color: colors.brand.primary,
    iconSize: '18px',
  },
  tooltip: {
    arrow: {
      offset: 16,
      width: 16,
      height: 8,
    },
    container: {
      boxShadow: shadows.soft,
      color: colors.greys.charcoal,
    },
    text: {
      color: colors.white,
      fontSize: fontSizes.sm,
      lineHeight: '24px',
    },
  },
};

const theme = {
  ...qantas,
  ...{
    components,
    icons,
    fontSizes,
    textStyles,
    dividerStyles,
    colors,
    breakpoints: BREAK_POINTS_PX.map((bp) => `${bp}px`),
    mediaQueries: BREAK_POINTS_PX.map(
      (bp) => `@media screen and (min-width: ${bp}px)`,
    ),
    shadows,
    zIndices: {
      foreground: 1,
      background: 0,
      popup: Number.MAX_SAFE_INTEGER,
    },
  },
  brand: 'qantas',
};

export const SCREEN_SIZE = {
  SM: `@media screen and (min-width: ${BREAK_POINTS_PX[0]}px)`,
  MD: `@media screen and (min-width: ${BREAK_POINTS_PX[1]}px)`,
  LG: `@media screen and (min-width: ${BREAK_POINTS_PX[2]}px)`,
};

export default theme;
