/*
  This is the type def that Sanity uses as the type for blocks.
  REF: apps/qantas-holidays/src/v2/types/libs/sanity/blockContentToReact.d.ts
*/
export type SanityBlocks = any[] | any;

const parseSanityBlocksToString = (sanityBlocks: SanityBlocks) =>
  sanityBlocks.reduce((finalStr: string, currentPara: { children: [] }) => {
    const paragraphStr = currentPara.children.reduce(
      (paraStr: string, currentStr: { text: string }) =>
        paraStr + currentStr.text,
      '',
    );
    return finalStr + paragraphStr;
  }, '');

export default parseSanityBlocksToString;
