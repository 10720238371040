import pino from 'pino';
import options from './config';

const logger = pino({
  ...options,
  serializers: {
    error: (e) => ({
      name: e.name,
      message: e.message,
      stack: e.stack,
    }),
  },
});

export default logger;
