import {
  PURE_CLOUD_DEPLOYMENT_KEY,
  PURE_CLOUD_ORG_GUID,
  PURE_CLOUD_QUEUE_NAME,
} from '../../../../../config/public';

export default {
  widgets: {
    main: {
      downloadGoogleFont: true,
      theme: 'light',
      lang: 'en',
    },
    webchat: {
      transport: {
        type: 'purecloud-v2-sockets',
        dataURL: 'https://api.mypurecloud.com.au',
        deploymentKey: PURE_CLOUD_DEPLOYMENT_KEY,
        orgGuid: PURE_CLOUD_ORG_GUID,
        interactionData: {
          routing: {
            targetType: 'QUEUE',
            targetAddress: PURE_CLOUD_QUEUE_NAME,
            priority: 2,
          },
        },
      },
      userData: {},
      form: {
        wrapper: `
        <div>
          <div>Get in touch to find your holiday</div>
        </div>
      `,
        inputs: [
          {
            id: 'firstname',
            name: 'firstname',
            maxlength: '100',
            placeholder: 'Please enter your first name',
            wrapper: '<p>{input}</p>',
          },
          {
            id: 'lastname',
            name: 'lastname',
            maxlength: '100',
            placeholder: 'Please enter your last name',
            wrapper: '<p>{input}</p>',
          },
        ],
      },
    },
  },
};
