import { useEffect, useRef } from 'react';
import { ABANDON_CART_MAX_IDLE_PERIOD } from '@/constants';
import { differenceInMilliseconds } from '@/lib/date';
import onAbandon from './helpers/onAbandon';
import { useAuth } from '@/components/Auth';

export const USER_ACTIVE_EVENT_TYPES = [
  'scroll',
  'mousedown',
  'keydown',
  'touchstart',
];

const AbandonCart = () => {
  const { memberDetails } = useAuth();

  const memberDetailsRef = useRef();
  useEffect(() => {
    memberDetailsRef.current = memberDetails;
  }, [memberDetails]);

  useEffect(() => {
    const onInactiveSession = () =>
      onAbandon('INACTIVE_SESSION', memberDetailsRef.current);

    let lastUpdatedAt = new Date();

    const intervals = 60 * 1000;

    const timer = setInterval(() => {
      if (!lastUpdatedAt) {
        return;
      }

      if (
        differenceInMilliseconds(new Date(), lastUpdatedAt) >=
        ABANDON_CART_MAX_IDLE_PERIOD
      ) {
        onInactiveSession();

        lastUpdatedAt = null;
      }
    }, intervals);

    const onActive = () => {
      lastUpdatedAt = new Date();
    };

    USER_ACTIVE_EVENT_TYPES.forEach((type) =>
      window.addEventListener(type, onActive),
    );

    return () => {
      clearInterval(timer);

      USER_ACTIVE_EVENT_TYPES.forEach((type) =>
        window.removeEventListener(type, onActive),
      );
    };
  }, []);

  useEffect(() => {
    const onDroppedSession = () =>
      onAbandon('DROPPED_SESSION', memberDetailsRef.current);

    window.addEventListener('beforeunload', onDroppedSession);

    return () => {
      window.removeEventListener('beforeunload', onDroppedSession);

      onDroppedSession();
    };
  }, []);

  return null;
};

export default AbandonCart;
