import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import useSWR from 'swr';
import BFF from '@experiences-ui/shared/services/BFF';

const usePreview = () => {
  const [loading, setLoading] = useState(false);
  const { isPreview } = useRouter();
  const { data, error, mutate } = useSWR('/preview', {
    fallbackData: { isPreview },
  });

  const clearPreview = useCallback(async () => {
    setLoading(true);

    await BFF.clearPreview()();

    mutate();

    setLoading(false);
  }, [mutate]);

  const enablePreview = useCallback(
    async (token: string) => {
      setLoading(true);

      await BFF.enablePreview()(token);

      mutate();

      setLoading(false);
    },
    [mutate],
  );

  return {
    preview: !error && !!data?.isPreview,
    loading,
    enablePreview,
    clearPreview,
  };
};

export default usePreview;
