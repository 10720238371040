import { CreatedEvent } from '../../types';

export const createSearchSubmitEvent = (): CreatedEvent => {
  return {
    eventInfo: {
      event: 'search_submit',
      event_data: {
        action: 'submit',
        component_type: 'search',
        component_variant: 'holiday packages',
      },
    },
    eventOptions: {
      debugMessage: 'GA4 Data Layer Event (Search Submit)',
    },
  };
};
