export const createMapEvent = ({
  groupName,
  itemName,
}: {
  groupName: string;
  itemName: string;
}) => {
  return {
    eventInfo: {
      event: 'map_location_click',
      event_data: {
        action: 'location_click',
        component_type: 'map',
        group_name: groupName,
        item_name: itemName,
      },
    },
    eventOptions: {
      debugMessage: 'GA4 Data Layer Event (Map Click)',
    },
  };
};
