const throttle = (fn: Function) => {
  let running = false;

  return (...args: any) => {
    if (running) {
      return;
    }

    running = true;

    window.requestAnimationFrame(() => {
      fn(...args);

      running = false;
    });
  };
};

export default throttle;
