import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {
  Box,
  Icon,
  Modal,
  NakedButton,
  Paragraph,
} from '@qga/roo-ui/components';
import Link from '@/v2/components/ui/Link';

const UnexpectedErrorModal = ({ onClose }) => {
  useEffect(() => {
    document.querySelector('#portal-root').innerHTML = '';
  }, []);

  return (
    <Modal
      appElement={
        document.querySelector('#__next') || document.querySelector('#root')
      }
      isOpen
    >
      <Box position="relative">
        <Modal.header variant="error">
          <Box fontSize="lg">Something went wrong</Box>
        </Modal.header>
        <Box position="absolute" right="0" top="0" bottom="0" pr="4" pt="4">
          <NakedButton data-testid="CLOSE_BUTTON" onClick={() => onClose()}>
            <Icon name="close" />
          </NakedButton>
        </Box>
      </Box>
      <Modal.body textAlign="left">
        <Paragraph textAlign="left">
          We&apos;re sorry, an unexpected error occurred while we were
          processing your request. Please try again, otherwise{' '}
          <Link.Text external href="/contact-us" shallow={false}>
            contact the Qantas Holidays team
          </Link.Text>{' '}
          if this issue persists.
        </Paragraph>
      </Modal.body>
    </Modal>
  );
};

UnexpectedErrorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default UnexpectedErrorModal;
