import pino from 'pino';

const redactions = [
  'error.config.headers.Authorization',
  'error.config.headers.Cookie',
  'error.config.data',
];

const axiosLogger = pino({
  redact: redactions,
});

export default axiosLogger;
