export type EventUser = {
  id?: string;
  qffHash?: string;
  loginStatus: 'logged in' | 'logged out';
  points?: `${number}` | number;
};

export const createUser = (user: EventUser) => {
  return {
    user_id: user.id,
    user_qff_hash: user.qffHash,
    user_login_status: user.loginStatus,
    user_points: user.points,
  };
};
