export const generateState = () => {
  const validChars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let array = new Uint8Array(40);
  window.crypto.getRandomValues(array);
  //@ts-ignore
  array = array.map((x) => validChars.codePointAt(x % validChars.length));
  //@ts-ignore
  const randomState: string = String.fromCharCode.apply(null, array);
  return randomState;
};
