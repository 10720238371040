import PropTypes from 'prop-types';
import React from 'react';
import {
  Box,
  Flex,
  Image,
  NakedButton,
  Text,
  Hide,
} from '@qga/roo-ui/components';

const FloatingEnquire = ({
  onClick,
  image,
  disabled,
  children,
  hasStickyFooter,
}) => (
  <Box
    data-testid="FLOATING_ENQUIRE"
    position="fixed"
    right="24px"
    bottom={hasStickyFooter ? '164px' : '54px'}
    zIndex="popup"
  >
    <Hide md lg>
      <NakedButton disabled={disabled} onClick={onClick}>
        <Box bg="white" p="2" borderRadius="rounded" boxShadow="soft">
          <Image height="32px" width="32px" src={image} alt="Enquire" />
        </Box>
      </NakedButton>
    </Hide>
    <Hide xs sm>
      <Flex
        alignItems="center"
        bg="white"
        boxShadow="soft"
        p="3"
        borderRadius={['rounded', null, 'default']}
      >
        <Image mr="2" height="32px" width="32px" src={image} alt="Enquire" />
        <Text>Need Help?</Text>
        <NakedButton
          ml="2"
          color="ui.link"
          hoverColor="ui.linkHover"
          disabled={disabled}
          onClick={onClick}
        >
          {children}
        </NakedButton>
      </Flex>
    </Hide>
  </Box>
);

FloatingEnquire.defaultProps = {
  disabled: false,
  hasStickyFooter: false,
};

FloatingEnquire.propTypes = {
  image: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  hasStickyFooter: PropTypes.bool,
};

export default FloatingEnquire;
