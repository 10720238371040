import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIsFeatureFlagLoaded } from '@/libs/v2/contexts/featureFlags';
import dataLayer from '@/v2/utils/dataLayer/dataLayer';
import { useGA4Events } from '../featureFlags/ga4Events/useGA4Events';
import parseSanityBlocksToString, {
  SanityBlocks,
} from '@/libs/v2/utils/parseSanityBlocksToString';

const useViewPromotionEvent = ({
  sanityBlocks,
}: {
  sanityBlocks: SanityBlocks;
}) => {
  const [hasBeenInvoked, setHasBeenInvoked] = useState(false);
  const shouldUseNewEvents = useGA4Events();
  const areFeatureFlagsReady = useIsFeatureFlagLoaded();

  const shouldInvokeEvent = useMemo(
    () => shouldUseNewEvents && areFeatureFlagsReady && !hasBeenInvoked,
    [areFeatureFlagsReady, hasBeenInvoked, shouldUseNewEvents],
  );

  const name = useMemo(
    () => parseSanityBlocksToString(sanityBlocks),
    [sanityBlocks],
  );

  const handleInvokeViewPromotionEvent = useCallback(() => {
    dataLayer.viewPromotionEvent({ name });
  }, [name]);

  useEffect(() => {
    if (shouldInvokeEvent) {
      handleInvokeViewPromotionEvent();
      setHasBeenInvoked(true);
    }
  }, [handleInvokeViewPromotionEvent, shouldInvokeEvent]);
};

export default useViewPromotionEvent;
