import { toDate, utcToZonedTime } from 'date-fns-tz';
import { isDate } from '../dateFns';
import format from '../format';

const AppTimeZone = 'Australia/Melbourne';
const toDateWithTimeZone = (input, timeZone) => {
  if (!isDate(input)) {
    return toDate(input, { timeZone });
  }

  // const timeZoneTime = input.toLocaleString('en-AU', { timeZone });
  return toDate(format("yyyy-MM-dd'T'HH:mm:ss.SSS")(input), { timeZone });
};

// set the input (string/Date) as a time in App timezone
export const toAppTime = (input) => toDateWithTimeZone(input, AppTimeZone);

// convert the input (Date) from the current timeZone to mapped time in App timeZone
// eslint-disable-next-line max-len
export const convertToAppTime = (input) => toDate(input, AppTimeZone);

// eslint-disable-next-line max-len
export const utcToAppTime = (input) => utcToZonedTime(input, AppTimeZone);

// eslint-disable-next-line max-len
export const getAppDateString = (input) =>
  format('yyyy-MM-dd')(utcToZonedTime(input, AppTimeZone));

export default toDateWithTimeZone;
