import AuthContext from './AuthContext';
import { useAuth } from './hooks/useAuth';
import { ReactNode } from 'react';
import { AUTH_STRATEGY, AuthStrategy } from './types';

const AuthProvider = ({
  children,
  authStrategy = AUTH_STRATEGY.OAUTH,
}: {
  children: ReactNode;
  authStrategy?: AuthStrategy;
}) => {
  const values = useAuth({
    authStrategy,
  });

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
