import Case from 'case';
import { CreatedEvent, EcommerceItem } from '../../types';

export type ViewItemListEvenProps = {
  origin?: string;
  destination?: string;
  items: EcommerceItem[];
  searchType: 'list' | 'map';
  name: string;
  usePoints: boolean;
  startDate?: string;
  endDate?: string;
  duration?: number;
  travellers?: {
    adults?: number;
    children?: number;
    infants?: number;
  };
};

export const createViewItemListEvent = ({
  destination,
  items,
  searchType,
  origin,
  name,
  usePoints,
  // Post-search data
  startDate,
  endDate,
  duration,
  travellers,
}: ViewItemListEvenProps): CreatedEvent => {
  const postSearchData = {
    start_date: startDate,
    end_date: endDate,
    duration,
    travellers_adult: travellers?.adults,
    travellers_children: travellers?.children,
    travellers_infant: travellers?.infants,
  };

  return {
    eventInfo: {
      event: 'view_item_list',
      event_data: {
        action: 'view',
        component_type: 'item_list',
        search_payment_toggle: usePoints ? 'points' : 'cash',
        available_package_count: items.length,
        destination,
        search_type: searchType,
        origin,
        ...postSearchData,
      },
      ecommerce: {
        currency: 'AUD',
        item_list_id: Case.snake(name),
        item_list_name: name,
        items,
      },
    },
    eventOptions: {
      debugMessage: 'GA4 Data Layer Event (View Item List)',
    },
  };
};
