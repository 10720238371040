import { EventOptions, PointsValue } from './types';

function reset(this: any) {
  this.reset();
}

export const createPush =
  (config: { useDebug?: boolean }) => (eventInfo: any, opts?: EventOptions) => {
    if (typeof window === 'undefined') return;

    const { useDebug } = config ?? {};
    const { debugMessage } = opts ?? {};

    if (useDebug) {
      console.log(debugMessage ?? 'Data Layer Event', eventInfo);
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(reset);
    window.dataLayer.push(eventInfo);
  };

const VALUE_TO_TEXT = {
  cash: 'use cash',
  points: 'use points',
  pointsPlusPay: 'use points plus pay',
} as const;

export const pointsValueToEventValue = (pointsValue?: PointsValue) =>
  pointsValue ? VALUE_TO_TEXT[pointsValue] : undefined;
