import { CreatedEvent } from '../../types';

export type SearchExitFieldArgs = {
  fieldName: string;
  fieldType: string;
  fieldValue: string;
};

export const createSearchExitFieldEvent = ({
  fieldName,
  fieldType,
  fieldValue,
}: SearchExitFieldArgs): CreatedEvent => {
  return {
    eventInfo: {
      event: 'search_exit_field',
      event_data: {
        action: 'exit_field',
        component_type: 'search',
        component_variant: 'holiday packages',
        item_name: fieldName,
        item_type: fieldType,
        item_value: fieldValue,
      },
    },
    eventOptions: {
      debugMessage: 'GA4 Data Layer Event (Search Exit Field)',
    },
  };
};
