import React from 'react';
import PropTypes from 'prop-types';
import { Box, Paragraph } from '@qga/roo-ui/components';

const FormControl = ({
  label,
  children,
  optional,
  htmlFor,
  labelProps,
  ...props
}) => (
  <Box
    {...props} // eslint-disable-line react/jsx-props-no-spreading
  >
    <Box color="greys.emperor" fontWeight="bold" {...labelProps}>
      <label htmlFor={htmlFor}>{label}</label>
    </Box>
    {children}
    {optional && (
      <Paragraph fontSize="sm" color="greys.steel" mb="0" mt="2">
        Optional
      </Paragraph>
    )}
  </Box>
);

FormControl.defaultProps = {
  optional: false,
};

FormControl.propTypes = {
  label: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  htmlFor: PropTypes.string.isRequired,
  optional: PropTypes.bool,
  labelProps: Box.propTypes,
  ...Box.propTypes,
};

export default FormControl;
