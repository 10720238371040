import { Global } from '@emotion/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Flex } from '@qga/roo-ui/components';
import FocusTrap from '@/components/FocusTrap';
import Portal from '@experiences-ui/shared/v2/components/Portal';
import ModalContext from './ModalContext';

const Modal = ({ children, onClose, width }) => (
  <Portal selector="portal-root">
    <ModalContext.Provider value={{ onClose }}>
      <Global
        styles={{
          body: {
            overflow: 'hidden',
          },
        }}
      />
      <FocusTrap>
        <Flex
          onClick={() => onClose && onClose()}
          alignItems={['stretch', null, 'center']}
          justifyContent="center"
          py={[0, null, 10]}
          position="fixed"
          zIndex="popup"
          top="0"
          bottom="0"
          right="0"
          left="0"
          bg="rgba(50,50,50,0.8)"
          data-testid="MODAL_BACKGROUND"
        >
          <Flex
            onClick={(event) => event.stopPropagation()}
            maxHeight="100%"
            flexDirection="column"
            width={[1, null, width]}
            bg="white"
          >
            {children}
          </Flex>
        </Flex>
      </FocusTrap>
    </ModalContext.Provider>
  </Portal>
);

Modal.defaultProps = {
  onClose: null,
  width: '768px',
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  width: PropTypes.string,
};

export default Modal;
