import Modal from './Modal';

import Header from './components/Header';
import Body from './components/Body';
import Footer from './components/Footer';

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;

export default Modal;
