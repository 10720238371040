export const createAccordionOpenEvent = ({
  groupName,
  itemName,
}: {
  groupName: string;
  itemName: string;
}) => {
  return {
    eventInfo: {
      event: 'accordion_open',
      event_data: {
        action: 'open',
        component_type: 'accordion',
        group_name: groupName,
        item_name: itemName,
      },
    },
    eventOptions: {
      debugMessage: 'GA4 Data Layer Event (Accordion Open)',
    },
  };
};
