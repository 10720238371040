// eslint-disable-next-line import/prefer-default-export
export const TILE_TYPE = {
  CROSS_SELL_BANNER: 'CROSS_SELL_BANNER',
  FEATURED_PROPERTY: 'FEATURED_PROPERTY',
  PROCURED_OFFER: 'PROCURED_OFFER',
};

export const EXCLUSIVE_OFFERS_PAGE_CAMPAIGN_SLUG = 'exclusive-offers';

export const RATING_TYPE = {
  AAA: 'AAA',
  SELF: 'SELF_RATED',
};

export const DEV_ENVIRONMENTS = ['localhost', 'dev', 'sit', 'staging'];

export const AU_MELB_TZ = 'Australia/Melbourne';
