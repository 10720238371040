import config from './config';

export const PLUGINS_PATH =
  'https://apps.mypurecloud.com.au/widgets/9.0.014.06/plugins/';

const getPureCloudWebChat = () => {
  if (!window.CXBus) {
    return null;
  }

  const { CXBus } = window;
  window._genesys = config; // eslint-disable-line no-underscore-dangle

  CXBus.configure({
    debug: false,
    pluginsPath: PLUGINS_PATH,
  });

  CXBus.loadPlugin('widgets-core');

  return CXBus.registerPlugin('PURE_CLOUD_WEB_CHAT');
};

export default getPureCloudWebChat;
