import { isWeekend, isWithinInterval, toDateWithTimeZone } from '@/lib/date';

const isOpeningHours = (specialHours) => {
  let dateTimeSydney;

  // Fix IE11 not support timeZone option
  try {
    dateTimeSydney = new Date().toLocaleString('en-US', {
      timeZone: 'Australia/Sydney',
    });
  } catch (e) {
    dateTimeSydney = new Date().toLocaleString('en-US');
  }

  const date = new Date(dateTimeSydney);

  const isSpecialOpeningHours = specialHours
    .map(({ start, end }) => ({
      start: toDateWithTimeZone(start),
      end: toDateWithTimeZone(end),
    }))
    .find((s) => isWithinInterval(date, s));

  if (isSpecialOpeningHours) {
    return true;
  }

  if (isWeekend(date)) {
    return false;
  }

  const h = date.getHours();
  if (h < 9 || h > 19) {
    return false;
  }

  return true;
};

export default isOpeningHours;
