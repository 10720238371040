import { differenceInDays } from '../date';

const mapNightsFromStay = (stay) => {
  if (!stay) return null;

  const { checkIn, checkOut } = stay;

  if (!checkIn || !checkOut) return null;

  return differenceInDays(new Date(checkOut), new Date(checkIn));
};

export default mapNightsFromStay;
