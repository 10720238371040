export const BOOKABLE_PERIOD = {
  min: 2,
  max: 365,
};

export const MAX_STAY = 29;

export const ISO_DATE_ONLY_FORMAT = 'yyyy-MM-dd';
export const MEDIUM_DATE_FORMAT = 'd MMM yyyy'; //TODO: replace all uses
export const TIMEZONE = 'Australia/Melbourne';

export const BREAK_POINTS_PX = [321, 768, 1100, 1280];

export const breakpoints = BREAK_POINTS_PX.map((bp) => `${bp}px`);

export const mediaQueries = breakpoints.map(
  (n) => `@media screen and (min-width: ${n})`,
);

export const SCREEN_SIZE = {
  SM: mediaQueries[0],
  MD: mediaQueries[1],
  LG: mediaQueries[2],
};

export const SEARCH_PARAMS_STORAGE_KEY = 'SEARCH_PARAMS_STORAGE_KEY';
