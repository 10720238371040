import { CreatedEvent, EcommerceDetailedItem } from '../../types';

export type ViewItemEventArgs = {
  item: EcommerceDetailedItem;
};

export const createViewItemEvent = ({
  item,
}: ViewItemEventArgs): CreatedEvent => ({
  eventInfo: {
    event: 'view_item',
    event_data: {
      action: 'view',
      component_type: 'item',
    },
    ecommerce: {
      value: item.price && (item.price?.toFixed(2) as string), // TODO Address undefined
      currency: 'AUD',
      items: [item],
    },
  },
  eventOptions: {
    debugMessage: 'GA4 Data Layer Event (View Item)',
  },
});
