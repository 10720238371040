import reqCustomProps from './reqCustomProps';
import logger from './logger';
import { sanitizeRef } from './sanitizeRef';
import { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';

const customLogLevel = (status: number) => {
  if (status >= 400 && status < 500) return 'warn';
  if (status >= 500) return 'error';
  if (status === 0) return 'error';
  return 'info';
};

const log = (reqConfig: AxiosRequestConfig, response: AxiosResponse) => {
  const level = customLogLevel(response?.status ?? 0);
  const child = logger.child(
    { ...reqCustomProps(), msg: 'request completed' },
    { level },
  );

  const reqURL = new URL(reqConfig.baseURL ?? '');
  const req = {
    method: reqConfig.method,
    url: reqConfig.url,
    query: reqConfig.params,
    headers: reqConfig.headers,
    origin: reqConfig.baseURL,
    host: reqURL.host,
    hostname: reqURL.hostname,
    protocol: reqURL.protocol,
  };
  sanitizeRef(req);

  const res = {
    statusCode: response?.status ?? 'ENOTFOUND',
    headers: response?.headers,
  };

  if (level === 'info') {
    child.info({ req, res });
  } else if (level === 'warn') {
    child.warn({ req, res });
  } else {
    child.error({ req, res });
  }
};

const info = (response: AxiosResponse) => {
  log(response.config, response);
};

const error = (error: AxiosError) => {
  log(error.config, error.response as any);
};

/**
 * pino-http only works with native node.js ServerResponse and Request.
 * pinoAxiosLogger is created to log axios request and response object in a structure similar to pin-http
 */
export const pinoAxiosLogger = {
  info,
  error,
};
