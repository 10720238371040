import { isEmpty } from 'ramda';
import { getTravellersCount, mapOccupants } from '..';

interface Travellers {
  adults?: number | string;
  children?: number | string;
  infants?: number | string;
  total?: number | string;
}

const getTotalTravellers = (
  travellers: Travellers | undefined,
): number | null => {
  if (!travellers || isEmpty(travellers)) {
    return null;
  }

  return (
    Number(travellers.total) || getTravellersCount(mapOccupants(travellers))
  );
};

export default getTotalTravellers;
