import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Box, Button, Flex, Heading } from '@qga/roo-ui/components';
import Divider from '@/components/Divider';
import FormControl from '@/components/FormControl';
import Input from '@/components/Input';
import Select from '@/components/Select';
import withProps from '@/components/withProps';
import useForm from '@/hooks/useForm';
import { isMobilePhone, isNotEmpty, isQFFOrEmpty } from '@/lib/validator';
import usePopulateData from './hooks/usePopulateData';
import useDateOptions from './hooks/useDateOptions';
import useFlyingFromOptions from './hooks/useFlyingFromOptions';

const Row = withProps({
  justifyContent: 'space-between',
  my: [null, null, '4'],
  flexWrap: ['wrap', null, 'no-wrap'],
})(Flex);

const Column = withProps({
  width: ['100%', null, 'calc(50% - 8px)'],
  my: ['2', null, '0'],
})(FormControl);

const Header = withProps({
  fontSize: ['md', null, 'lg'],
  my: ['2', null, '4'],
})(Heading.h3);

const getScheduledTime = (date, time) => {
  if (!date || !time) {
    return '';
  }

  const scheduledTime = new Date(new Date(date).setHours(time)).toISOString();

  return scheduledTime;
};

const Form = ({ onSubmit }) => {
  const { getValue, getError, handleValueChange, handleSubmit, setFormData } =
    useForm({}, ({ date, time, ...data }) =>
      onSubmit({
        ...data,
        scheduledTime: getScheduledTime(date, time),
      }),
    );

  const handlePopulateData = useCallback(
    (data) => {
      setFormData(data);
    },
    [setFormData],
  );
  usePopulateData(handlePopulateData);

  const flyingFromOptions = useFlyingFromOptions();
  const dateOptions = useDateOptions();

  return (
    <form onSubmit={handleSubmit}>
      <Box mb="4">
        Skip the queue. Submit your contact details and a holiday sales
        specialist will call you as soon as possible.
        <br />
        If your enquiry is relating to a flight booking made directly with
        Qantas and not as part of a holiday package please call <b>13 13 13</b>.
      </Box>
      <Header>Your Details</Header>
      <Row>
        <Column label="Name" htmlFor="request_cb_name">
          <Input
            id="request_cb_name"
            error={getError('name', { onFormDirty: true })}
            onChange={handleValueChange('name', [
              [isNotEmpty, 'Name is required'],
            ])}
            value={getValue('name')}
          />
        </Column>
        <Column label="Phone number" htmlFor="request_cb_phone_number">
          <Input
            id="request_cb_phone_number"
            error={getError('phoneNumber', { onFormDirty: true })}
            onChange={handleValueChange('phoneNumber', [
              isMobilePhone,
              'Please input a valid mobile number',
            ])}
            value={getValue('phoneNumber')}
          />
        </Column>
      </Row>
      <Row>
        <Column label="Qantas Frequent Flyer" htmlFor="request_cb_qff" optional>
          <Input
            id="request_cb_qff"
            error={getError('qff', { onFormDirty: true })}
            onChange={handleValueChange('qff', [
              isQFFOrEmpty,
              'Please enter a valid Frequent Flyer Number',
            ])}
            value={getValue('qff')}
          />
        </Column>
        <Column label="Flying from" htmlFor="request_cb_flying_from" optional>
          <Select
            id="request_cb_flying_from"
            error={getError('flyingFrom', { onFormDirty: true })}
            onChange={handleValueChange('flyingFrom')}
            value={getValue('flyingFrom')}
            options={flyingFromOptions}
          />
        </Column>
      </Row>
      <Divider my="4" />
      <Header>When would you like to be contacted?</Header>
      <Row>
        <Column label="Time" htmlFor="request_cb_time" optional>
          <Select
            id="request_cb_time"
            error={getError('time', { onFormDirty: true })}
            onChange={handleValueChange('time')}
            value={getValue('time')}
            options={[
              {
                value: '',
                text: 'Please select',
              },
              {
                value: '9',
                text: 'Morning (09:00 - 12:00)',
              },
              {
                value: '12',
                text: 'Early afternoon (12:00 - 15:00)',
              },
              {
                value: '15',
                text: 'Late afternoon (15:00 - 19:00)',
              },
            ]}
          />
        </Column>
        <Column label="Date" htmlFor="request_cb_date" optional>
          <Select
            id="request_cb_date"
            error={getError('date', { onFormDirty: true })}
            onChange={handleValueChange('date')}
            value={getValue('date')}
            options={dateOptions}
          />
        </Column>
      </Row>
      <Box mt={['2', null, '0']}>
        <Button variant="primary" width={['100%', null, 'auto']}>
          REQUEST CALLBACK
        </Button>
      </Box>
    </form>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default Form;
