import styled from '@emotion/styled';
import { themeGet, space, variant, maxHeight } from 'styled-system';
import PropTypes from 'prop-types';

const dividerStyles = variant({
  key: 'dividerStyles',
  prop: 'orientation',
});

const Divider = styled.hr`
  border: 0;
  ${dividerStyles}
  border-color: ${themeGet('colors.greys.alto')};
  margin: 0;
  ${space}
  ${maxHeight}
`;

Divider.defaultProps = {
  orientation: 'horizontal',
};

Divider.propTypes = {
  orientation: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
};

export default Divider;
