import { UI } from '@/v2/types';
import type { AlertBannerVariants } from '@/v2/components/ui/AlertBanner/AlertBanner';
import AlertBanner from '@/v2/components/ui/AlertBanner';
import PAGE_NAME_ROUTES from '@/v2/constants/pageNameRoutes';
import useShowSiteMessage from '@experiences-ui/shared/v2/hooks/useShowSiteMessage';
import PromotionBanner from '../PromotionBanner';

const EXCLUDED_PATHS = ['/bookings/[bookingId]'];

export type SiteMessageProps = {
  siteMessage?: UI.Message;
  variant: AlertBannerVariants;
};

const SiteMessage = ({ siteMessage, variant }: SiteMessageProps) => {
  const showMessage = useShowSiteMessage(
    siteMessage,
    PAGE_NAME_ROUTES,
    EXCLUDED_PATHS,
  );

  const shouldShowMessage = showMessage && siteMessage?.message && !!variant;

  if (!shouldShowMessage) return null;

  return variant === 'info' ? (
    <PromotionBanner sanityBlocks={siteMessage.message} />
  ) : (
    <AlertBanner variant={variant} sanityBlocks={siteMessage.message} />
  );
};

export default SiteMessage;
