import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import React, { ReactNode } from 'react';
import {
  ButtonStyleProps,
  ColorProps,
  FontWeightProps,
  FontSizeProps,
  SpaceProps,
} from 'styled-system';

export interface LinkProps extends NextLinkProps, SpaceProps, ColorProps {
  external?: boolean;
  children: ReactNode;
}

export type LinkTextVariants = 'light' | 'dark';
export interface LinkTextProps
  extends LinkProps,
    FontWeightProps,
    FontSizeProps {
  variant?: LinkTextVariants;
}

export interface LinkButtonProps extends LinkProps, ButtonStyleProps {}
export interface LinkNakedProps extends LinkProps, FontWeightProps {}

const withLink =
  <T extends LinkProps>(Component: React.ElementType) =>
  ({ href, as, shallow, external, ...props }: T) => (
    <NextLink href={href} as={as} shallow={shallow} passHref>
      <Component
        {...(external && {
          target: '_blank',
          rel: 'noopener noreferrer nofollow external',
        })}
        {...props}
      />
    </NextLink>
  );

export default withLink;
