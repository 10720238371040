import { Member } from '../../../components/AuthContext/types';
import { useContext, useState } from 'react';
import BFF from './../../../../services/BFF';
import Cookies from 'js-cookie';
import { Cookie } from '../../../constants/storage';
import { OAuthContext } from '../../../../v2/utils/oauth/OAuthProvider';

export const useMemberDetails = () => {
  const { options } = useContext(OAuthContext);
  const [details, setDetails] = useState<Member | undefined>();
  const [error, setError] = useState<any | undefined>();

  const fetchMember = async () => {
    // Add additional Cookie check before API call;
    const hasLSLUserId = typeof Cookies.get(Cookie.LSL_USER_ID) === 'string';

    if (!hasLSLUserId) {
      return;
    }

    return BFF.getMember()()
      .then((detail) => {
        if (detail?.memberId && Object.keys(detail).length > 1) {
          setDetails(detail);
          options.onLogin(detail);
        }
      })
      .catch((e) => {
        setError(e);
        setDetails(undefined);
      });
  };

  return {
    error,
    member: details ? details : null,
    getMember: fetchMember,
    clearMember: () => setDetails(undefined),
  };
};
