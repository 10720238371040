import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from '@qga/roo-ui/components';

const FormErrorMessage = ({ text, noArrow }) => {
  return noArrow ? (
    <ErrorMessage mt="3">{text}</ErrorMessage>
  ) : (
    <ErrorMessage mt="3" arrow="top">
      {text}
    </ErrorMessage>
  );
};

FormErrorMessage.defaultProps = {
  noArrow: false,
};

FormErrorMessage.propTypes = {
  text: PropTypes.string.isRequired,
  noArrow: PropTypes.bool,
};

export default FormErrorMessage;
