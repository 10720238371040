import { useRef, useState, useMemo } from 'react';
import { pathOr, isEmpty, values } from 'ramda';

const mapDataFromFormData = (formData) => {
  const data = {};

  Object.keys(formData).forEach((key) => {
    const { value } = formData[key];

    data[key] = value;
  });

  return data;
};

const mapFormDataFromData = (data) => {
  const formData = {};

  Object.keys(data).forEach((key) => {
    const value = data[key];

    formData[key] = {
      value,
    };
  });

  return formData;
};

const getErrorMessage = (value, validation) => {
  if (!validation) {
    return null;
  }

  if (validation.every(Array.isArray)) {
    let message;

    for (let i = 0; i < validation.length; i += 1) {
      message = getErrorMessage(value, validation[i]);

      if (message) {
        break;
      }
    }

    return message;
  }

  const [validator, message, ...options] = validation;
  const result = validator(value, ...options);

  if (result) {
    return null;
  }

  return message;
};

const useForm = (initialData = {}, onSubmit) => {
  const [formData, setFormData] = useState(mapFormDataFromData(initialData));
  const [formDirty, setFormDirty] = useState(false);
  const registeredValidation = useRef({});

  const getFormData = (key) => formData[key] || {};

  const getValue = (key) => getFormData(key).value || '';

  const handleValueChange = (key, validation) => {
    registeredValidation.current[key] = validation;

    return (event) => {
      const value = pathOr(event, ['target', 'value'], event);
      const error = getErrorMessage(value, validation);

      setFormData((prevData) => ({
        ...prevData,
        [key]: {
          value,
          error,
          dirty: true,
        },
      }));
    };
  };

  const getError = (key, behavior) => {
    const { error, dirty } = getFormData(key);

    if (
      behavior &&
      ((behavior.onFormDirty && !formDirty) ||
        (behavior.onInputDirty && !dirty))
    ) {
      return null;
    }

    return error;
  };

  const handleReset = () => setFormData(mapFormDataFromData(initialData));

  const validate = () => {
    setFormDirty(true);

    const error = {};

    Object.keys(registeredValidation.current).forEach((key) => {
      const prevError = getError(key);

      if (prevError) {
        error[key] = prevError;

        return;
      }

      const validation = registeredValidation.current[key];
      const value = getValue(key);

      const errorMessage = getErrorMessage(value, validation);

      if (!errorMessage) {
        return;
      }

      error[key] = errorMessage;
    });

    if (isEmpty(error)) {
      return true;
    }

    setFormData((prevData) => {
      const newData = {};

      Object.keys(error).forEach((key) => {
        newData[key] = {
          ...prevData[key],
          error: error[key],
        };
      });

      return {
        ...prevData,
        ...newData,
      };
    });

    return false;
  };

  const createSubmitHandler = (cb) => (event) => {
    if (event) {
      event.preventDefault();
    }

    const result = validate();
    if (!result) {
      return;
    }

    if (!cb) {
      return;
    }

    const data = mapDataFromFormData(formData);

    cb(data);
  };

  const isFormDirty = useMemo(
    () =>
      values(formData)
        .map(({ dirty }) => dirty)
        .some(Boolean),
    [formData],
  );

  return {
    getFormData,
    setFormData,
    getValue,
    getError,
    validate,
    handleValueChange,
    createSubmitHandler,
    handleSubmit: createSubmitHandler(onSubmit),
    handleReset,
    isFormDirty,
  };
};

export default useForm;
