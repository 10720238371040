import { useEffect, useState } from 'react';

const useSessionStorage = (key, initialValue) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    try {
      const storageValue = sessionStorage.getItem(key);
      if (storageValue) {
        setValue(JSON.parse(storageValue));
      }
    } catch (error) {
      // If user is in private mode or has storage restriction
    }
  }, [key]);

  useEffect(() => {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      // If user is in private mode or has storage restriction
    }
  }, [key, value]);

  return [value, setValue];
};

export default useSessionStorage;
