import BaseApp from 'next/app';
import Head from 'next/head';
import React from 'react';
import { Hide } from '@qga/roo-ui/components';
import AbandonCart from '@/components/AbandonCart';
import DocumentTitle from '@/components/DocumentTitle';
import GlobalPreviewIndicator from '@/components/GlobalPreviewIndicator';
import Providers from '@/components/Providers';
import { AuthProvider } from '@experiences-ui/shared/v2/components/AuthContext';
import SimplicityHeader from '@/components/SimplicityHeader';
import SWRProvider from '@/components/SWRProvider';
import { WebChatProvider } from '@/components/WebChat';
import Ciutadella from './components/Ciutadella';
import * as Sentry from '@sentry/nextjs';
import { getUserUuid } from '@experiences-ui/shared/v2/utils/localStorage';
import SiteMessage from '@/v2/components/ui/SiteMessage';
import { OAuthProvider } from '@experiences-ui/shared/v2/utils/oauth';
import dataLayer from '@/v2/utils/dataLayer/dataLayer';
import {
  AUTH_API_URL,
  LSL_AUTH_URL,
  LSL_USERNAME,
  REDIRECT_URL,
} from '@/config/public';

export class App extends BaseApp {
  componentDidMount() {
    // Get User ID from local storage
    const userUuid = getUserUuid();

    // Set the Sentry ID
    Sentry.setUser({ id: userUuid });
  }

  render() {
    const { Component, pageProps } = this.props;

    const warningMessage =
      pageProps?.warningMessage || pageProps?.app?.warningMessage;

    return (
      <>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Head>
        <DocumentTitle />
        <Ciutadella />
        <SWRProvider>
          <Providers>
            <WebChatProvider>
              <OAuthProvider
                dataLayer={dataLayer}
                config={{
                  clientId: LSL_USERNAME,
                  qffAuthApiUrl: AUTH_API_URL,
                  lslAuthApiUrl: LSL_AUTH_URL,
                  redirectUri: REDIRECT_URL,
                  client: 'qhp',
                }}
              >
                <AuthProvider>
                  <GlobalPreviewIndicator />
                  <SiteMessage siteMessage={warningMessage} variant="warning" />
                  <Hide xs sm>
                    <SimplicityHeader />
                  </Hide>
                  <Component
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...pageProps}
                  />
                  <AbandonCart />
                </AuthProvider>
              </OAuthProvider>
            </WebChatProvider>
          </Providers>
        </SWRProvider>
      </>
    );
  }
}

export default App;
