import { CreatedEvent } from '../../types';
import { createUser, EventUser } from '../utils/createUser';

export type LoginEventArgs = EventUser;

export const createLoginEvent = (user: LoginEventArgs): CreatedEvent => {
  return {
    eventInfo: {
      event: 'login',
      event_data: {
        action: 'login',
      },
      user: createUser(user),
    },
    eventOptions: {
      debugMessage: 'GA4 Data Layer Event (Login)',
    },
  };
};
