import { useEffect } from 'react';
import { useAuth } from '@/components/Auth';

const usePopulateData = (onDataChange) => {
  const { memberDetails } = useAuth();

  useEffect(() => {
    if (!memberDetails) {
      return;
    }

    onDataChange((state) => {
      const newState = { ...state };

      const { firstName, lastName, memberId, phoneNumber } = memberDetails;

      const data = {
        name: `${firstName} ${lastName}`,
        qff: memberId,
        phoneNumber,
      };

      Object.keys(data).forEach((key) => {
        if (state[key]) {
          return;
        }

        newState[key] = {
          value: data[key],
        };
      });

      return newState;
    });
  }, [memberDetails, onDataChange]);
};

export default usePopulateData;
